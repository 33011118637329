import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter  } from '@angular/core';

//dynamic forms
import { FormBuilder, FormGroup, FormArray, Validators , FormControl} from '@angular/forms';
import { ValidatorService } from '../../../../_services/validator.service';
import { JsonPipe } from '@angular/common';

import { CommonDataService } from '../../../../_services/common-data.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-form-modal',
  templateUrl: './dynamic-form-modal.component.html',
  styleUrls: ['./dynamic-form-modal.component.scss']
})
export class DynamicFormModalComponent implements OnInit, OnChanges {

  @Output() formSubmittedEvent = new EventEmitter<string>();

  selectGrouping = "";

  fileSystem = "staticFiles"; //base64 or staticFiles

  @Input() data;
  @Input() form;
  @Input() postEndpoint;
  @Input() formId;

  innerFormData;
  modelData;
  files: any;
  endpoint;
  prepopulateData;

  //static files vars
  staticFiles: any = [];
  filesDisplay: any = [];
  staticFilesToUpload: Array<File> = [];

  //... end static vars

  modelId = 0;

  filesToUpload = [];

  btnText = "Submit";

  submitted = false;
  submitButtonText= 'Submit';
  modelForm: FormGroup;
  modelSections: FormArray;
  repeatableSection: FormArray;
  formControlsProps; //for each of the formControlName we create, we add the control properties into this var and loop through it in the html to create the form fields

  constructor(private formBuilder: FormBuilder, private validatorService: ValidatorService, private _data: CommonDataService) { }

  ngOnInit() {

    this.modelData = this.form;
    this.endpoint = this.postEndpoint;

    this.prepopulateData = this.data;

    if(this.formId) {
      this.modelId = this.formId;
    }

    // console.log(this.prepopulateData)
    // console.log(this.modelData)

    this.initialiseForm();

    // this.innerFormData = {
    //   'modelName': this.modelData.modelName,
    //   "sections": []
    // }

    // Object.values(this.modelData.sections).forEach(value=>{
    //   this.innerFormData.sections.push(value)
    // });


    // this.modelForm = this.formBuilder.group({
    //   modelName: this.modelData.modelName,
    //   sections: this.formBuilder.array([])
    // });

    // var prepopulateFlag = false;


    // this.createModelSections('', prepopulateFlag);



    // this.createModelSections('Cars');

    // this.getControlProperties("Car-VinNumber", "questionShort")

  }

  initialiseForm() {

    this.innerFormData = {
      'modelName': this.modelData.modelName,
      "sections": []
    }

    Object.values(this.modelData.sections).forEach(value=>{
      this.innerFormData.sections.push(value)
    });

    //this.items = [...this.items, {id: 1, name: 'New item'}];

    this.modelForm = this.formBuilder.group({
      modelName: this.modelData.modelName,
      sections: this.formBuilder.array([])
    });

    var prepopulateFlag = false;
    // prepopulateFlag = this.prePopulate;

    this.createModelSections('', prepopulateFlag);
  }

  // controls(path: any) {
  //   const group = this.modelForm.get(path) as FormGroup;
  //   return Object.keys(group.controls)
  // }

  // getSectionControl() {
  //   let control = <FormArray>this.modelForm.controls.sections;
  //   return  control.at(0);
  // }

  createModelSections(cloneSection, prepopulate) {

    let control = <FormArray>this.modelForm.controls.sections;

    // this.innerFormData.sections.forEach((x, index) => {
    for (const [index, x] of Object.entries(this.modelData.sections)) {

      //console.log(`${x} ${index}`); //x is the sectionObject, index is the name of the section/section key
      let sectionName = `${index}`;

      if(cloneSection != "" && cloneSection != sectionName)
        continue;
      
      for (const [objectKey, sectionelementControls] of Object.entries(x)) {
        //console.log(`${objectKey} ${sectionelementControls}`); // objectKey keys in a section, either elements or repeatable key if set. value is the value of the key, either an array of elements or boolean for repeatable
        
        if(x['repeatable'] === true) {

          if (`${objectKey}` == "clones" && prepopulate === true) {
            // color is undefined
            for (const [cloneIndex, cloneObject] of Object.entries(sectionelementControls)) {

              for (const [cloneKeyNames, cloneKeyObject] of Object.entries(cloneObject)) {
                if(`${cloneKeyNames}` == "elements") {
                  this.createSectionControls(sectionName, cloneKeyObject, control); 
                }
              }
              
            }

            continue;
          }

        }

        if(`${objectKey}` == "elements") {

          this.createSectionControls(sectionName, sectionelementControls, control); 
  
          // control.push(this.formBuilder.group({ 
               
          //   elements: this.createSectionControls(sectionName, sectionelementControls, control) 
          // }))

        }        
      }

      //console.log(this.modelForm.value)
      
    }
  }

  createSectionControls(sectionName: String, sectionelementControls: any, control: any) {
      let arr = new FormArray([])
      let group = new FormGroup({});

      for (const [sectionIndex, sectionObject] of Object.entries(sectionelementControls)) {

          for (const [controlName, controlValue] of Object.entries(sectionObject)) {

            //console.log('element details:' +`${controlValue}`)

            if(`${controlName}` == "name") {

              // alert('element controls');

              var key = sectionName+"-"+`${controlValue}`;
              var value = '';

              var validations = this.getFieldValidations(sectionObject);

              //prepopulation happens here...
              value = this.prepopulateFormControl(sectionName, `${controlValue}`, sectionIndex);
              // if(sectionObject["value"])
              //   value = sectionObject["value"];

                group.addControl(key, new FormControl(value, validations));
              
              // arr.push(this.formBuilder.group({ 
              //   [key] : [value, validations]
              // }))

            }
          }
      }

      control.push(group);
      // return arr;
  }

  getFieldValidations(field: any) {
    return this.validatorService.runValidations(field);
  }

  cloneRepeatableSection(sectionName, cloneCount) {



  }

  toArray(obj) {
     return Object.keys(obj).map(key => obj[key]) 
  }

  toArrayObjects(obj) {
    return Object.keys(obj);
 }



  getControlProperties(controlName : String, metaDataType: String) : any {

    var elementKeyData;
    var controlDetails = controlName.split('-');

    if(controlDetails[0] && controlDetails[1]) {

      var sectionName = controlDetails[0];
      var elementName = controlDetails[1];

      //alert(elementName)


      var mx = this.modelData.sections[sectionName].elements;

      for (const [elemIndex, elemObject] of Object.entries(mx)) {

        if(elemObject['name'] == elementName) {

          // alert(`${elemIndex}`)

          for (const [elemName, elemValue] of Object.entries(elemObject)) {

            if(`${elemName}` == metaDataType) {
              elementKeyData = `${elemValue}`;
              // special case for answerOptions
              if(metaDataType == 'answerOptions') {
                elementKeyData = elemValue;
              }

            }
            
          }
        }
        
        
      }

    }
    
    return elementKeyData;
  }


  onFilesAdded(files: File[], keyName, sectionIndex ) {
    //console.log(files);

    //alert('accepted')

    this.filesToUpload[sectionIndex] = {};
    this.filesToUpload[sectionIndex][keyName] = [];
    let fileCount = 0;
   
    files.forEach(file => {
      const reader = new FileReader();
      let fileObject = {};

      fileObject['name'] = file.name;
      fileObject['size'] = file.size;
      fileObject['type'] = file.type;
      fileObject['contentType'] = 'base64';
   
      reader.onload = (e: ProgressEvent) => {
        const content = (e.target as FileReader).result;

        fileObject['content'] = content;
        fileObject['content'] = fileObject['content'];
        // fileObject['content'] = fileObject['content'];
        //console.log(btoa(fileObject['content']))
        //console.log(content)

        // alert('whooray')
   
        // this content string could be used directly as an image source
        // or be uploaded to a webserver via HTTP request.
        
      };

      this.filesToUpload[sectionIndex][keyName][fileCount] = fileObject;
   
      // use this for basic text files like .txt or .csv
     //reader.readAsText(file);

     fileCount++;
   
      // use this for images
     reader.readAsDataURL(file);

     //this.filesToUpload.push(<File>file)
    });

    
    //console.log(this.filesToUpload);
  }

  postDataToApi(model, id) {

    this.btnText = "Please Wait...";

    var innerFormData = {
      'modelName': model.modelName,
      'id': this.modelId,
      "sections": []
    }

    innerFormData.sections = model.sections;

    var form_data = [];
    form_data.push(innerFormData)

    let modelObject1 = new FormData();

    Object.values(form_data).forEach(value => {
      modelObject1.append("form_data[]", JSON.stringify(value));
    });

    if(this.fileSystem == "staticFiles") {
      const filesStatic: Array<File> = this.staticFilesToUpload;
      //console.log(filesStatic);
  
      for(let i =0; i < filesStatic.length; i++){
          modelObject1.append("images[]", filesStatic[i]);
      }
    }


    this._data.dynamicFormPost(modelObject1, this.endpoint)
    .pipe(first())
    .subscribe(

      data => {
        //console.log(data);
        this.prepopulateData = data['result']['model']['modelData']['formFieldsEntities'];

        this.formSubmittedEmitEvent(data);

        this.btnText = "Submit";

      },

      error => {
        console.log(error);
        this.formSubmittedEmitEvent([]);
        //   iziToast.success({
        //     title: 'Error!',
        //     position: 'topRight',
        //     icon: 'icon-circle-check',
        //     message: 'Failed to retrieve profile info.'
        // });
        this.btnText = "Submit";

      },

      () => {
          return this.modelData;
      }
    );

  }
   
  onFilesRejected(files: File[]) {

    //alert('rejected')
    //console.log(files);
  }

  submit() {

    this.submitted = true;
    //alert('working magic!')
    this.btnText = 'Please Wait...';


    if (this.modelForm.invalid) {
      
      this.btnText = "Submit"; 

      return;
    }

    let modelObject1 = this.modelForm.value;

    if(this.fileSystem == "base64") {

      for (const [sectionIndex, sectionObject] of Object.entries(this.filesToUpload)) {

        let files = [];
  
        for (const [keyName, file] of Object.entries(sectionObject)) {
          
          modelObject1.sections[sectionIndex][`${keyName}`] = file;
        }
  
      }

    }

    this.postDataToApi(modelObject1, 0);

    //console.log(modelObject1)

  
    // for(let i =0; i < files.length; i++){
    //     modelObject1.append("uploads[]", files[i]);
    // }
  }

  getFiles(item, i) {
    if(typeof  this.filesToUpload[i] != "undefined") 
      return this.filesToUpload[i][item];

    else 
      return [];
  }

  fileSize (size) {

    if (isNaN(size))
      size = 0;

    size /= 1024;

    if (size < 1024) {

      return size.toFixed(2) + ' Kb';
    }
      
    size /= 1024;

    if (size < 1024) {

      return size.toFixed(2) + ' Mb';
    }

    size /= 1024;

    if (size < 1024) {

      return size.toFixed(2) + ' Gb';
    }

    size /= 1024;

    return size.toFixed(2) + ' Tb';
  }

  removeFile(fileIndex,item , i) { 
    if(typeof  this.filesToUpload[i] != "undefined") {
      this.filesToUpload[i][item].splice(fileIndex, 1);
    }
    
  }

  prepopulateFormControl(section, controlName, sectionIndex) {

    if(this.prepopulateData) {

        if(this.prepopulateData[section]) {

          if(this.prepopulateData[section][controlName]) {
              return this.prepopulateData[section][controlName];
          }

        }
    }

    return '';

  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed

    if (changes['postEndpoint']) {
      this.endpoint = changes['postEndpoint'].currentValue;
    }
      
    if (changes['data']) 
      this.prepopulateData = changes['data'].currentValue;

    if (changes['formId']) {
      this.modelId = changes['formId'].currentValue;
    }

    if (changes['form']) {
      this.modelData = changes['form'].currentValue;
      this.initialiseForm();
    }

   
  }

  formSubmittedEmitEvent(response) {
    this.formSubmittedEvent.emit(response)
  }

  //Static files implementation

  uploadFile(event) {

    // this.filesToUpload = <Array<File>>event.target.files;
    // this.filesToUpload = <Array<File>>event;
    let filesToUpload = <Array<File>>event;

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.filesDisplay.push(element.name)
      this.staticFilesToUpload.push(<File>element)
    }
    
    // this.modelForm.get('images').setValue(this.files);

    // console.log(this.files);
    // console.log(event);
    
    
  }

  deleteAttachment(index) {
    //console.log(this.staticFilesToUpload);
    this.filesDisplay.splice(index, 1)
    this.staticFilesToUpload.splice(index, 1)
    //console.log(this.staticFilesToUpload);

  }

}
