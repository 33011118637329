import { Component, OnInit } from '@angular/core';
import { Router  , ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-user-context-models',
  templateUrl: './user-context-models.component.html',
  styleUrls: ['./user-context-models.component.scss']
})
export class UserContextModelsComponent implements OnInit {

  modelName;
  models;
  contextId;

  pageComponents = environment.dashboardConfig.landingPageConfig.components;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      //console.log(params['page'])
      if(params['modelName']) {
        this.modelName = params['modelName'];
      }

      if(params['contextId']) {
        this.contextId = params['contextId'];
      }
      
    });

    this.models = environment.dashboardConfig.modelSettings.userContextModels.models;

  }

}
