
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from '../../../../environments/environment';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-dynamic-data-table',
  templateUrl: './dynamic-data-table.component.html',
  styleUrls: ['./dynamic-data-table.component.scss']
})
export class DynamicDataTableComponent implements OnInit, AfterViewInit, OnInit , OnChanges  {

  @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective) mdbTable: MdbTableDirective
  elements: any = [];
  previous: any = [];

  //settings
  @Input() modelDetailsName;
  modelData;
  config;
  modelName;
  createModel = false;



  // headElements = ['ID', 'First', 'Last', 'Handle'];
  headElements = [];
  
  searchText: string = '';

  maxVisibleItems: number = 20;

  constructor(private cdRef: ChangeDetectorRef,private httpClient: HttpClient, private _data: CommonDataService, private router: Router, private sanitizer: DomSanitizer) {}

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {

    this.modelName = this.modelDetailsName;

    this.config = environment.dashboardConfig.modelSettings[this.modelName].partialInfoSetup;

    if(environment.dashboardConfig.modelSettings[this.modelName].createModel)
      this.createModel = true;

    for (const [elemIndex, elemObject] of Object.entries(this.config.fields)) {
      //this.headElements.push(this.config.fields[elemIndex].key)
    }

    this.queryModelFromApi(this.config);

    // for (let i = 1; i <= 25; i++) {
    //   this.elements.push({id: i.toString(), first: 'Wpis ' + i, last: 'Last ' + i, handle: 'Handle ' + i});
    // }

    // this.mdbTable.setDataSource(this.elements);
    // this.elements = this.mdbTable.getDataSource();
    // this.previous = this.mdbTable.getDataSource();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  addNewRow() {
    this.mdbTable.addRow({
      id: this.elements.length.toString(),
      first: 'Wpis ' + this.elements.length,
      last: 'Last ' + this.elements.length,
      handle: 'Handle ' + this.elements.length
    });
    this.emitDataSourceChange();
  }

  addNewRowAfter() {
    this.mdbTable.addRowAfter(1, {id: '2', first: 'Nowy', last: 'Row', handle: 'Kopytkowy'});
    this.mdbTable.getDataSource().forEach((el: any, index: any) => {
      el.id = (index + 1).toString();
    });
    this.emitDataSourceChange();
  }

  removeLastRow() {
    this.mdbTable.removeLastRow();
    this.emitDataSourceChange();
    this.mdbTable.rowRemoved().subscribe((data: any) => {
      //console.log(data);
    });
  }

  removeRow() {
    this.mdbTable.removeRow(1);
    this.mdbTable.getDataSource().forEach((el: any, index: any) => {
      el.id = (index + 1).toString();
    });
    this.emitDataSourceChange();
    this.mdbTable.rowRemoved().subscribe((data: any) => {
      //console.log(data);
    });
  }

  emitDataSourceChange() {
    this.mdbTable.dataSourceChange().subscribe((data: any) => {
      //console.log(data);
    });
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  queryModelFromApi(model) {

    let queryData = new FormData();
    queryData.append('modelName', model.name);

    if(model.hasOwnProperty("queryExtras")) {

      for (const [objectKey, queryObject] of Object.entries(model.queryExtras)) { 
        queryData.append(queryObject['key'] , queryObject['value']);
      }

    }

    //alert(model.hasOwnProperty("queryExtras"));

    this._data.getModelData(queryData) 
    .pipe(first())
    .subscribe(

      data => {
        //console.log(data);
        this.modelData = data['covers'].reverse();

        this.pushElements()

      },

      error => {
        console.log(error);
        //   iziToast.success({
        //     title: 'Error!',
        //     position: 'topRight',
        //     icon: 'icon-circle-check',
        //     message: 'Failed to retrieve profile info.'
        // });

      },

      () => {

        
        // this.mdbTable.setDataSource(this.modelData);
        // this.elements = this.mdbTable.getDataSource();
        // this.previous = this.mdbTable.getDataSource();
          // return this.modelData;
      }
    );

  }

  pushElements() {

    
    // for (let i = 1; i <= 25; i++) {
    //   this.elements.push({id: i.toString(), first: 'Wpis ' + i, last: 'Last ' + i, handle: 'Handle ' + i});
    // }
    let pushHeadElements = true;

    for (const [modelIndex, modelObject] of Object.entries(this.modelData)) {

      var item = {};
      
      for (const [elemIndex, elemObject] of Object.entries(this.config.fields)) {


        if(pushHeadElements) {

          this.headElements.push(elemObject['key']);
        }

       
        item[elemObject['key']] = this.getModelValueFromMappingKey(modelObject, elemObject['value']);

        // this.mdbTable.addRow({
        //   id: this.elements.length.toString(),
        //   first: 'Wpis ' + this.elements.length,
        //   last: 'Last ' + this.elements.length,
        //   handle: 'Handle ' + this.elements.length
        // });
        
        
      }

      pushHeadElements = false;

      item['id'] = 'buttonlinkidentifier-'+modelObject['id'];

      this.elements.push(item);
      this.emitDataSourceChange();
      //console.log(item);
    }

    this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();

  }

  getModelValueFromMappingKey(currentModelIndex, valueMappingKey) {

    //systemManagedEntities->paymentDetails->total

    var model = currentModelIndex;

    if(valueMappingKey == 'id') {
      return String(model['id']);
    }

    if(valueMappingKey == 'created') {

      var currentDate = new Date(model['created']*1000);

      var date = currentDate.getDate();
      var month = currentDate.getMonth(); //Be careful! January is 0 not 1
      var year = currentDate.getFullYear();

      var dateString = date + "-" +(month + 1) + "-" + year;
      return String(dateString);
    }

    if(valueMappingKey == 'last_updated') {
      var currentDate = new Date(model['last_updated']*1000);

      var date = currentDate.getDate();
      var month = currentDate.getMonth(); //Be careful! January is 0 not 1
      var year = currentDate.getFullYear();

      var dateString = date + "-" +(month + 1) + "-" + year;
      return String(dateString);
    }


    let objectPath = valueMappingKey.split('->');

    var objectName, sectionName, valueKey, value;

    if(objectPath[0]  && objectPath[1] ) {

      value = '';

      if(model['cover']['model']['modelData'].hasOwnProperty(objectPath[0]) ) {

        if(model['cover']['model']['modelData'][objectPath[0]].hasOwnProperty(objectPath[1])) {
            value = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

            // console.log(model);
      
            if(objectPath[2]) {
              value = value[objectPath[2]];
            }
        }

      }

      
      
        

      //console.log(this.elements);

    }
    
    if(value === '')
      value = 'N/A';

    return String(value);

  }

  private createHiddenElement(name: string, value: string): HTMLInputElement {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }

  deleteToModel(modelId) {
    this.httpClient.post(environment.apiUrl+"/model-remove-entry",
    {
        "modelName": this.modelName,
        "mid": modelId
    })
    .subscribe(
        data => {
            //console.log("POST Request is successful ", data);
            
        },
        error => {
            console.log("Error", error);
        },

        () => {

        }
    ); 
  }

  navigateToModel(modelId) {

    let linkText = modelId.split('-');
    let LinkId = '';

    if(linkText[1]) {
      LinkId = linkText[1];
    }

    // alert(LinkId)

    if(this.config['button'] !== undefined) {

      if(this.config['button']['action'] == 'resorce-link') {

        const form = window.document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", environment.apiUrl + this.config['button']['resorce_link']);
        //use _self to redirect in same tab, _blank to open in new tab
        form.setAttribute("target", "_blank"); 
        // form.setAttribute("target", "_self"); 
  
        form.appendChild(this.createHiddenElement('modelName', this.modelName));
        form.appendChild(this.createHiddenElement('mid', LinkId));
        window.document.body.appendChild(form);
  
        //console.log(form);
        form.submit();
      }

      else {
        let url = '/dashboard/model-details/'+this.modelName+'/'+LinkId;
        this.router.navigate([url]);
      }
    }

    else {
      let url = '/dashboard/model-details/'+this.modelName+'/'+LinkId;
      this.router.navigate([url]);
    }
  }

  navigateToResource(modelId, resourceLink) {

  }

  toArray(obj) {
    return Object.keys(obj).map(key => obj[key]) 
 }

 createNewModel() {
  let url = '/dashboard/add-model/'+this.modelName;
  this.router.navigate([url]);
 }

 ngOnChanges(changes: SimpleChanges) {
  // only run when property "data" changed
  if(this.modelName){
    if (changes['modelDetailsName'] && changes['modelDetailsName'].currentValue != this.modelName) {
        this.modelName = changes['modelDetailsName'].currentValue;
        window.location.reload();
    }
  }
  

  
}

}
