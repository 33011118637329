import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  modelForm: FormGroup;
  buttonText = "Login";
  loading = false;

  apiUrl;

  constructor(
    private authService: AuthService,  private router: Router, private httpClient: HttpClient,  private formBuilder: FormBuilder
  ) {
    this.authService.logout();
  }

  ngOnInit() {

    this.apiUrl = environment.apiUrl;
    this.modelForm = this.formBuilder.group({

      auth_username: ['', Validators.required], 
      auth_password: ['', Validators.required], 

    });
  }

  onSubmit() {

    this.login(); // run combine function
  }

  login() {

    this.buttonText = "Please Wait...";
    this.loading = true;

    if (this.modelForm.invalid) {

      //show errors
      this.buttonText = "Login";

      return;
    }

      let password = this.modelForm.controls['auth_password'].value;
      let username = this.modelForm.controls['auth_username'].value;
    
      this.httpClient.post(this.apiUrl+"/api-login",
          {
              "auth_password": password,
              "auth_username": username
          })
          .subscribe(
              data => {
                  //console.log("POST Request is successful ", data);
                  this.loading = false;
              },
              error => {
                  console.log("Error", error);
              },

              () => {

                this.authService.getUserProfileDetails();
                this.router.navigate(['dashboard/home']);
              }
          ); 
    }


}
