import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//needed modules
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

//view components
import { DynamicFormViewComponent } from './dynamic-form-view/dynamic-form-view.component';

@NgModule({
  declarations: [DynamicFormViewComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  exports:      [ DynamicFormViewComponent ]

})
export class DynamicFormModule { }
