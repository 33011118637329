import { Component, OnInit , Input} from '@angular/core';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import { Router  , ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-form-wizard',
  templateUrl: './form-wizard.component.html',
  styleUrls: ['./form-wizard.component.scss']
})
export class FormWizardComponent implements OnInit {

  @Input() modelName;

  modelData;
  config;
  accodionSettings;
  modelFormTemplate;
  postEndpoint  = "api-update-model-data";
  selected;
  selectedItemIndex;
  formData;
  Formsections;
  formId = 0;

  sectionFiles = [];
  files = false;

  configSections;
  
  formObjectSections = false;
  formReady = false;

  constructor(private _data: CommonDataService, private route: ActivatedRoute) { }

  ngOnInit() {

    let createForm = true;

    this.route.params.subscribe((params) => {

      if(params['id']) {
        // this.modelName = params['modelName'];
        if(this.formId != params['id']) {
          this.formId = params['id'];
          this.queryModelFromApi(this.modelName);
          createForm = false;
        }
      } 
    });

    this.config = environment.dashboardConfig.modelSettings[this.modelName];

    if(this.config['createModel'] === true) {

      if(this.config.accodionDisplayInfo.formFields === true) {
        this.formObjectSections = true;
        this.configSections= this.config.accodionDisplayInfo.formFieldObjects['01'];
        this.Formsections = this.configSections['sections'];
        this.selected = this.configSections.sections[0].section;
      }

      else {
        this.Formsections = this.config['createModelSettings']['sections'];

        //console.log(this.Formsections)

        this.selected = this.config['createModelSettings']['sections'][0]['key'];
      }
        
      this.selectedItemIndex = 0;

      
    }

    if(createForm)
      this.createModelForm();
    
  }

  createModelForm() {

    let queryData = new FormData();
    queryData.append('modelName', this.modelName);
    queryData.append('objectKeys', this.getSelected());
  
    this._data.createModelForm(queryData) 
    .pipe(first())
    .subscribe(
  
      data => {
        
        this.modelFormTemplate = data['definitionObjectForm'][this.modelName];
        let imagesDone = this.createImages();
        this.formReady = true;

        
  
      },
  
      error => {
        console.log(error);
  
      },
  
      () => {
  
      }
    );
  }

  createImages() {
    //lets check if this section has any associated files
    let sectionName = this.getSelected();

    this.sectionFiles = [];
    this.files = false;

    if(this.formData.hasOwnProperty('files')) {

      let imagesKey = 'images-'+sectionName;
      if(this.formData['files'].hasOwnProperty(imagesKey)) {

        let imageArray = this.formData['files'][imagesKey];

        if(typeof imageArray != "undefined" && imageArray != null && imageArray.length != null
        && imageArray.length > 0) {

          this.sectionFiles = imageArray;
          this.files = true;
          console.log(this.sectionFiles);
          //alert('hahahaha');

        }

      }
      
    }

    return true;
  }

  queryModelFromApi(model) {

    let queryData = new FormData();
    queryData.append('modelName', model);
  
    if(this.formId > 0) {
      queryData.append('mid', this.formId+'');
    }
  
    this._data.getModelData(queryData) 
    .pipe(first())
    .subscribe(
  
      data => {

        this.formData = data['covers'][0]['cover']['model']['modelData']['formFieldsEntities'];

        this.select(this.selected, this.selectedItemIndex);
  
      },
  
      error => {
        console.log(error);

  
      },
  
      () => {
  
          return this.modelData;
      }
    );
  
  }

  select(item, i) {
    this.selected = item;
    this.selectedItemIndex = i;
    this.createModelForm();
  }

  isActive(item) {
      return this.selected == item;
  }

  getSelected() {
    return this.selected
  }

  toArray(obj) {
    return Object.keys(obj).map(key => obj[key]) 
  }

  onFormSubmmited($event) {

    //this.formReady = false;

    let response = $event;

    //now we first check if we have the next section to submit
    this.formId = response['result']['id'];

    if(this.config.accodionDisplayInfo.formFields === true) { 
      if(this.configSections['sections'].hasOwnProperty(this.selectedItemIndex + 1)) {  
        //we do have the next section
        let curremtIndex = this.selectedItemIndex + 1;
        let item = this.configSections['sections'][curremtIndex]['section'];
        this.select(item, curremtIndex);
   
      }

    }

    else {
      if(this.config['createModelSettings']['sections'].hasOwnProperty(this.selectedItemIndex + 1)) {  
        //we do have the next section
        let curremtIndex = this.selectedItemIndex + 1;
        let item = this.config['createModelSettings']['sections'][curremtIndex]['key'];
        this.select(item, curremtIndex);
   
      }
    }

    this.formData = response['result']['model']['modelData']['formFieldsEntities'];
   // this.formReady = true;

    

    //this.formData = response['data'];

    //go to the next section if its there...

    //change the section key...then change the form id ...then change the form data
  }

  switchFormObject(group) {

    let key = group + 1;
    key = "0"+key;
    //this.accodionSettings= environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFieldObjects[key];
    this.configSections= this.config.accodionDisplayInfo.formFieldObjects[key];
    this.Formsections = this.configSections['sections'];
    this.selected = this.configSections.sections[0].section;
    this.selectedItemIndex = 0;
    this.createModelForm();

    //this.selectedGroupKey[group] = this.accodionSettings.sections[0].section;
  }

  

}
