import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { CommonDataService } from '../../../_services/common-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-dashboard-landing-page',
  templateUrl: './dashboard-landing-page.component.html',
  styleUrls: ['./dashboard-landing-page.component.scss']
})

export class DashboardLandingPageComponent implements OnInit, AfterViewInit, OnDestroy {

  recentModelsConfig = environment.dashboardConfig.landingPageConfig.recentModels.models;

  dynamicTables = environment.dashboardConfig.landingPageConfig.dataTable.models;

  pageComponents = environment.dashboardConfig.landingPageConfig.components;

  pageReady = false;
  
  public isCollapsed = false;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {

    this.pageReady = true;
  }

  ngOnDestroy() {
    this.pageReady = false;
  }

  mergeConflicts() {

    this.pageReady = false;

      this.httpClient.post(environment.apiUrl+"/merge_conflicts",
      {
          "modelName": ""
      })
      .subscribe(
          data => {
              //console.log("POST Request is successful ", data);
              this.pageReady = true;
          },
          error => {
              console.log("Error", error);
          },

          () => {
          }
      ); 
  }

}
