import { Component, OnInit, Input , OnChanges, SimpleChanges} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-files-section',
  templateUrl: './files-section.component.html',
  styleUrls: ['./files-section.component.scss']
})
export class FilesSectionComponent implements OnInit , OnChanges {

  @Input() files;
  @Input() title;

  fileData = [];
  displayFiles = false;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {

    //this.fileData = this.files;

    // if(typeof(this.files) === 'object') {
    //   var size = Object.keys(this.files).length;

    //   if(size > 0) {
    //     this.displayFiles = true;
    //     this.fileData = this.files;
    //   }
    // }

    if(typeof this.files != "undefined" && this.files != null && this.files.length != null && this.files.length > 0) {

      this.fileData = this.files;

    }

    
    
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['files'] ) {
    
      if(typeof changes['files'].currentValue != "undefined" && changes['files'].currentValue != null && changes['files'].currentValue.length != null && changes['files'].currentValue.length > 0) {

        this.displayFiles = true;
        this.fileData = changes['files'].currentValue;

        //alert('files changed')
  
      }

      else {
        //alert('files changed empty')
        this.displayFiles = false;
        this.fileData = [];
      }

      
        //console.log(this.fileData);

        // var size = Object.keys(changes['files'].currentValue).length;

        // if(size > 0) {
        //   this.displayFiles = true;
        //   this.fileData = changes['files'].currentValue;
        // }
    }
  }

  getImageSrc(file) {

    // data:{{file.type}};{{file.contentType}}
    let content = btoa(file.content)
    let filePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:'+file.type+';'+file.contentType+',' 
                 + content);
    return filePath;
  }

  getCorrectImagePathFromTemp(tmpUrl) {

    let urlParts = tmpUrl.split('public/');

    let srcUrl = environment.fileUrl+urlParts[1];

    return srcUrl;
    // console.log(srcUrl)

  }

}
