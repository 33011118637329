import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import {AlertService} from 'ngx-alerts';

@Component({
  selector: 'app-file-importer-form',
  templateUrl: './file-importer-form.component.html',
  styleUrls: ['./file-importer-form.component.scss']
})
export class FileImporterFormComponent implements OnInit {

  fileSystem = "staticFiles"; //base64 or staticFiles

  staticFiles: any = [];
  filesDisplay: any = [];
  staticFilesToUpload: Array<File> = [];

  postEndpoint  = "api-import-units";

  btnText = "Import";
  loading = false;

  constructor(private _data: CommonDataService, private alertService: AlertService) { }

  ngOnInit() {
  }

  uploadFile(event) {

    // this.filesToUpload = <Array<File>>event.target.files;
    // this.filesToUpload = <Array<File>>event;
    let filesToUpload = <Array<File>>event;

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.filesDisplay.push(element.name)
      this.staticFilesToUpload.push(<File>element)
    }
    
    // this.modelForm.get('images').setValue(this.files);

    // console.log(this.files);
    // console.log(event);
    
    
  }

  deleteAttachment(index) {
    //console.log(this.staticFilesToUpload);
    this.filesDisplay.splice(index, 1)
    this.staticFilesToUpload.splice(index, 1)
    //console.log(this.staticFilesToUpload);

  }

  postDataToApi(requestType) {

    if(this.loading) {

      // alert('Wait: Import still in progress! ')
      this.alertService.warning('Wait: Import still in progress!');
      return true;
    }
      
    this.btnText = "Please wait...";
    this.loading = true;

    let modelObject1 = new FormData();

    if(this.fileSystem == "staticFiles") {
      const filesStatic: Array<File> = this.staticFilesToUpload;
      //console.log(filesStatic);

      //modelObject1.append("zip_file_import", filesStatic[1]);
  
      for(let i =0; i < filesStatic.length; i++){
          modelObject1.append("zip_file_import"+i, filesStatic[i]);
      }
    }


    this._data.uploadLargeFiles(modelObject1, this.postEndpoint)
    .pipe(first())
    .subscribe(

      data => {
        //console.log(data);

      },

      error => {
        console.log(error);
        // alert('Issues flagged during import: Contact Support')
        this.alertService.danger('Issues flagged during import: Contact Support');
        //   iziToast.success({
        //     title: 'Error!',
        //     position: 'topRight',
        //     icon: 'icon-circle-check',
        //     message: 'Failed to retrieve profile info.'
        // });
        this.btnText = "Import";
        this.loading = false;

      },

      () => {

        if(requestType == "unitsff") {

          this.postEndpoint = "api-import-images";
          this.loading = false;
          this.postDataToApi("images");
      
        }

        else {
          //alert('Import Completed!')

          this.alertService.success('Import Completed!');

          this.btnText = "Import";
          this.loading = false;

          this.staticFilesToUpload = [];
        }

        
      }
    );

  }

  fileSize (size) {

    if (isNaN(size))
      size = 0;

    size /= 1024;

    if (size < 1024) {

      return size.toFixed(2) + ' Kb';
    }
      
    size /= 1024;

    if (size < 1024) {

      return size.toFixed(2) + ' Mb';
    }

    size /= 1024;

    if (size < 1024) {

      return size.toFixed(2) + ' Gb';
    }

    size /= 1024;

    return size.toFixed(2) + ' Tb';
  }

}
