import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-side-bar-menu',
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.scss']
})
export class SideBarMenuComponent implements OnInit {

  private openFlyout = false;

  //menu config
  menuConfig = environment.dashboardConfig.menu.pages;

  private toggleSidebar() {
    this.openFlyout = !this.openFlyout;
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

  menuNavigateToLink(modelName: String) {

    this.router.navigate([modelName]);
    this.openFlyout = !this.openFlyout;
  }



}
