import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { switchMap, map, first } from 'rxjs/operators';
// import { Subject } from 'rxjs/Subject';
import { Subject } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    //'Content-Type':  'application/json',
    //'Accept': 'application/json',
  }),
  withCredentials: false
};

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  private modelData = new BehaviorSubject<any>(null); // store for quote data
  public modelData$ = this.modelData.asObservable();

  //set session data...

  //current order data
  currentOrder:any;
  subscription:any;
  queryParams = {mid:0};

  //query params subscription
  queryParamsChanged = new Subject<any>();
  
  constructor(private http: HttpClient) { 

    //lets create a subscription of 
    this.subscription = this.queryParamsChanged.subscribe(
      (data) => {
        this.queryParams = data;
      }
    );
  }

  // General Data


  //APi Data endpoints...
  dynamicFormPost(data, endpoint) {
    return this.http.post<any>(`${environment.apiUrl}/`+endpoint, data, httpOptions)
  }
  
  getModelData (data) {
    //console.log(data.get('modelName'));
    if(data.get('modelName')) {

      if(data.get('modelName') == 'botUser' || data.get('modelName') == 'User')
        return this.http.post<any>(`${environment.apiUrl}/query-users-data`, data, httpOptions)

      else
        return this.http.post<any>(`${environment.apiUrl}/query-model-data`, data, httpOptions)
    }
    else
      return this.http.post<any>(`${environment.apiUrl}/query-model-data`, data, httpOptions)
  } // Get model data

  getUsersData (data) {
    return this.http.post<any>(`${environment.apiUrl}/query-users-data`, data, httpOptions)
  } // Get model data

  updateModelData (data) {
    return this.http.post<any>(`${environment.apiUrl}/update-model-data`, data, httpOptions)
  } // Get existing customer loan

  getDefinationObject (data) {
    return this.http.post<any>(`${environment.apiUrl}/get-definition-object`, data, httpOptions)
  } // get the object nodel 

  createModelForm (data) {
    return this.http.post<any>(`${environment.apiUrl}/create-model-form`, data, httpOptions)
  } // get the object nodel 

  searchData (data) {
    return this.http.post<any>(`${environment.apiUrl}/search-model-data`, data, httpOptions)
  } // Get existing customer loan

  //user functions
  updateProfile (data) {
    return this.http.post<any>(`${environment.apiUrl}/update-registration-data`, data, httpOptions)
  } // Update the profile of the current user...

  getProfile () {
    return this.http.get<any>(`${environment.apiUrl}/get-user-info`,  httpOptions)
  } // Update the profile of the current user...
  
  // Get Requests - Common Requests
  getRequest () {
    return this.http.get<any>(`${environment.apiUrl}/not-yet-implemented`, httpOptions)
  }

  uploadLargeFiles (data, endpoint) {
    return this.http.post<any>(`${environment.apiUrl}/`+endpoint, data, 

    {
      headers: new HttpHeaders({
        // 'Content-Type':  'undefined',
        //'Accept': 'application/json',
      }),
      withCredentials: false
    }
    
    )
  }
  
  
}
