import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxEchartsModule } from 'ngx-echarts';
import { BasicComponent } from './pie-charts/basic/basic.component';
import { ModelStatsComponent } from './pie-charts/model-stats/model-stats.component';

@NgModule({
  declarations: [BasicComponent, ModelStatsComponent],
  imports: [
    CommonModule,
    NgxEchartsModule
  ],
  exports:      [ BasicComponent, ModelStatsComponent ]
})
export class AnalyticsModule { }
