import { Component, OnInit, Input  } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-email-success',
  templateUrl: './email-success.component.html',
  styleUrls: ['./email-success.component.scss']
})
export class EmailSuccessComponent implements OnInit {

  @Input() data;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}
