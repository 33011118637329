import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CommonDataService } from '../_services/common-data.service';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept': 'application/json',
  }),
  withCredentials: false
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public http: HttpClient, private _data: CommonDataService) { }


  // public login(userName: string, password: string)  {
    
  //   return this.http.post<any>(`${environment.apiUrl}/api-login`, { userName: userName, password: password }, httpOptions);
    
  // }

  public login(formData: any)  {
    
    return this.http.post<any>(`${environment.apiUrl}/api-login`, formData , httpOptions);
    
  }

  public requestPasswordReset(userName: string) {

  	var endpoint = 'forgot-password';
    return this.dataRequest({ userName: userName }, endpoint);
  }

  public registerUser(data: any) {

  	var endpoint = 'api-register-user';
    return this.dataRequest( data, endpoint);

  }

  public isUserLoggedIn() : boolean {

	if (localStorage.getItem("authkey") === null && localStorage.getItem("apikey") === null) {
	  //...
	  return false;
	}

	return true;
  }

  public logout() {
  	localStorage.removeItem('authkey');
    localStorage.removeItem('apikey');
  }

  public getAuthKey() {
  	return localStorage.getItem("authkey");
  }

  public getApiKey() {
  	return localStorage.getItem("apikey");
  }

  public authenticated() {
    return false;
  }

  public saveSessionDetails(dataObject: any, objectName : any){
      // Create item:
      sessionStorage.setItem(objectName, JSON.stringify(dataObject));
  }

  public getSessionDetails(objectName : any){
    return JSON.parse(sessionStorage.getItem(objectName));
  }

  public getUserProfileDetails() {
      // Initiate new model creation/update method
      var userDetails;

      this._data.getProfile() 
        .pipe(first())
        .subscribe(
          data => {
            userDetails =  data['cover']['model']['modelData']['formFieldsEntities'];
          },
          error => {
            console.log(error);
  
  
          },
  
          () => {
            // iziToast.success({
            //     title: 'Success!',
            //     position: 'topRight',
            //     icon: 'icon-circle-check',
            //     message: 'Your profile updated successfuly.'
            // });
            this.saveSessionDetails(userDetails['PersonalDetails'], 'PersonalDetails');
          }
        );
    
  }

  dataRequest(data: any, endpoint: string) {
    return this.http.post(`${environment.apiUrl}/` , data);
  }
  

}
