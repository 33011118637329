import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';

import { environment } from '../../../../environments/environment';


export interface House {
  status: string;
  count: string;
}

const houseStatus: House[] = [
];

const houseStatusCosts : House[] = [];

const platformStatusCosts : House[] = [];

const platformwigthingBIQH : House[] = [];

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent implements OnInit {

  pageReady = false;
  dataSource;
  dataSource2;
  dataSource3;
  dataSource4;
  constructor(private httpClient: HttpClient) { }

  ngOnInit() {

    this.getStats();
  }

  displayedColumns: string[] = ['status', 'count'];
  //dataSource = houseStatus;

  getStats() {
    this.httpClient.post(environment.apiUrl+"/view_summary_stats",
          {
 
          })
          .subscribe(
              data => {
                this.pageReady = true;
                  //console.log("POST Request is successful ", data);

                  for (const [objectKey, objectValue] of Object.entries(data['items']['totalUnitsPerStatus'])) {
                    houseStatus.push({status: `${objectKey}`, count: `${objectValue['count']}`})
                  }

                  this.dataSource = houseStatus;

                  for (const [objectKey, objectValue] of Object.entries(data['items']['costsPerStatus'])) {
                    houseStatusCosts.push({status: `${objectKey}`, count: `${objectValue['total']}`})
                  }

                  this.dataSource2 = houseStatusCosts; 

                  for (const [objectKey, objectValue] of Object.entries(data['items']['costsPerPlatform'])) {

                    if(objectKey == "PlumbingAndElectrical")
                      platformStatusCosts.push({status: "DemolishAndRebuild", count: `${objectValue['totalCost']}`})
                    
                    else
                      platformStatusCosts.push({status: `${objectKey}`, count: `${objectValue['totalCost']}`})
                  }

                  this.dataSource3 = platformStatusCosts; 

                  // for (const [objectKey, objectValue] of Object.entries(data['items']['biqhPerPlatform'])) {
                  //   var score = Math.floor(objectValue['score'] / objectValue['total']);
                  //   platformStatusCosts.push({status: `${objectKey}`, count: String(score)})
                  // }
                  

                  for (const [key, objectValue] of Object.entries(data['items']['biqhPerPlatform'])) {
  
                    var score = Math.floor(objectValue['score'] / objectValue['total']);
                    platformwigthingBIQH.push({status: `${key}`, count: String(score)})
                  }

                  this.dataSource4 = platformwigthingBIQH; 
                  
              },
              error => {
                  console.log("Error", error);
              },

              () => {
              }
          ); 
  }

}
