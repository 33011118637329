import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';


@Component({
  selector: 'app-dynamic-form-view',
  templateUrl: './dynamic-form-view.component.html',
  styleUrls: ['./dynamic-form-view.component.scss']
})
export class DynamicFormViewComponent implements OnInit {

  @Input() modelData;
  @Input() postBackLink;

  modelForm: FormGroup;
  modelSections: FormArray;
  repeatableSection: FormArray;
  formControlsProps; //for each of the formControlName we create, we add the control properties into this var and loop through it in the html to create the form fields

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.modelForm = this.formBuilder.group({
      modelName: '',
      sections: this.formBuilder.array([])
    });

    this.createModelSections();

  }

  createModelSections() {
    let control = <FormArray>this.modelForm.controls.sections;
    this.modelData.sections.forEach(x => {
      if(x.repeatable == true) {
        control.push(this.formBuilder.group({ 
          name : x.name, 
          repeatable : x.repeatable,
          elements: this.buildRepeatableSection(x) 
        }))
      }

      else {
        control.push(this.formBuilder.group({ 
          name : x.name, 
          repeatable : x.repeatable,
          elements: this.createSectionControls(x) 
        }))
      }
      
    })
  }

  createSectionControls(sectionControls: any) {
      let arr = new FormArray([])
      sectionControls.elements.forEach(y => {
        var key = y.name;
        var value = y.value || "";

        // var controlObject = {};
        // controlObject[key] = value;
        // arr.push(this.formBuilder.group(controlObject))

        arr.push(this.formBuilder.group({ 
          [key] : value
        }))
      })

      return arr;
  }

  buildRepeatableSection(sectionData: any) {

  }

  getControlProperties(controlName : String) : String {
    return ""
  }

}
