import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router  , ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-model-list-view',
  templateUrl: './model-list-view.component.html',
  styleUrls: ['./model-list-view.component.scss']
})
export class ModelListViewComponent implements OnInit, AfterViewInit {

  modelName;
  contextId;
  config;
  pageReady = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {

      if(params['modelName']) {
        this.modelName = params['modelName'];
      }
      
    });

    this.config = environment.dashboardConfig.modelSettings[this.modelName].partialInfoSetup;
  }

  ngAfterViewInit() {
    this.pageReady = true;
  }

}
