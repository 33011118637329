import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
import { echartStyles } from '../../echart-styles';
import { ChartsModule } from 'angular-bootstrap-md';

import { CommonDataService } from '../../../../_services/common-data.service';

@Component({
  selector: 'app-model-stats',
  templateUrl: './model-stats.component.html',
  styleUrls: ['./model-stats.component.scss']
})
export class ModelStatsComponent implements OnInit {

  @Input() chartData;
  @Input() chartName;

  statsData: any;
  charts: any[] = [];

    reports = {
        "House Types" : {
            "segments" : {
            
                    "RDP Units" : {
                    "queryParams" : [{
                        "key" : "houseDetails->status" ,
                        "value" : "RDP House"
                    }]
                    },
        
                    "Partially Assessed Units" : {
                    "queryParams" : [{
                        "key" :"houseDetails->status",
                        "value" : "Not Assessed/Locked"
                    }]
                },
        
                "Rebuilt by Owner" : {
                    "queryParams" : [{
                        "key" : "houseDetails->status",
                        "value" : "Rebuilt by Owner"
                    }]
                },

                "Unbuilt Site(No RDP)" : {
                    "queryParams" : [{
                        "key" : "houseDetails->status",
                        "value" : "Unbuilt Site(No RDP)"
                    }]
                },

                "Incomplete House Built" : {
                    "queryParams" : [{
                        "key" : "houseDetails->status",
                        "value" : "Incomplete House Built"
                    }]
                },

                "Other Building/Structure" : {
                    "queryParams" : [{
                        "key" : "houseDetails->status",
                        "value" : "Other Building/Structure"
                    }]
                },

                "Rebuild(Damaged Unit)" : {
                    "queryParams" : [{
                        "key" : "houseDetails->status",
                        "value" : "Rebuild(Damaged Unit)"
                    }]
                },

                "Extended Unit" : {
                    "queryParams" : [{
                        "key" : "houseDetails->status",
                        "value" : "Extended Unit"
                    }]
                }
            }
        },

        "Houses Per User" : {
            "segments" : {
        
                "Bryson" : {
                    "queryParams" : [{
                        "key" : "context_id" ,
                        "value" : "15"
                    }]
                },

                "Marufu" : {
                    "queryParams" : [{
                        "key" : "context_id" ,
                        "value" : "16"
                    }]
                },

                "Prince" : {
                    "queryParams" : [{
                        "key" : "context_id" ,
                        "value" : "17"
                    }]
                },

                "Prosper" : {
                    "queryParams" : [{
                        "key" : "context_id" ,
                        "value" : "18"
                    }]
                },

                "Vusi" : {
                    "queryParams" : [{
                        "key" : "context_id" ,
                        "value" : "19"
                    }]
                },

                "Dakalo" : {
                    "queryParams" : [{
                        "key" : "context_id" ,
                        "value" : "23"
                    }]
                },

                "Len" : {
                    "queryParams" : [{
                        "key" : "context_id" ,
                        "value" : "22"
                    }]
                },
   
            }
        }
    }

	constructor(private _data: CommonDataService) {

        this.createReportCharts()
    }

	ngOnInit() {
    }
    
    createReportCharts() {
        for (const [reportName, reportData] of Object.entries(this.reports)) {
            this.generateChart(reportData.segments, reportName);
        }
    }

    generateChart(segments, reportName) {
            // Query stats data
        let queryData = new FormData();
        queryData.append('modelName', 'forensicEngineeringAssement');
        queryData.append('segments', JSON.stringify(segments));


        this._data.dynamicFormPost(queryData, 'model-reports').subscribe (
        data => {
            this.statsData = data['reports']['segments'];
            //console.log(this.statsData);
            let stat = {};
            stat['name'] = reportName;

            let dataHolder = [];

            Object.keys(this.statsData).forEach(key => {
                
                if (typeof this.statsData[key] === 'object') {
                    // 1. Collect series data

                    let innerObject = this.statsData[key];
                    

                    var holder = {};
                    holder['value'] = innerObject['totalCount'];
                    holder['name'] = innerObject['segmenetKey'];
                    dataHolder.push(holder);
                    


                    // Object.keys(innerObject).forEach(key => {
                    //     let innerValue = innerObject[key];
                    //     var holder = {};
                    //     holder['value'] = innerValue;
                    //     holder['name'] = key;
                    //     dataHolder.push(holder)
                    // });

                    //stat['name'] = key;
                    // if (key === 'entities') { stat['name'] = 'Common User Issues'; }
                    // else if (key === 'intents') { stat['name'] = 'User Objectives';}
                    // else if (key === 'status') { stat['name'] = 'Chat Statuses'; }
                    // else { stat['name'] = key; }

                    
                }
            });

            stat['type'] = 'pie';
                    stat['radius'] = '75%';
                    stat['center'] = ['50%', '50%'];
                    stat['data'] = dataHolder;
                    stat['itemStyle'] = {
                            emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    };

                    // 2. Create series data

                    let seriesHolder = [];
                    seriesHolder.push(stat);

                    // 3. Create chart object
                    let chart = {};
                    chart['color'] = ['rgb(245,160,0)', 'rgb(235,101,36)', 'rgb(131,187,38)', 'rgb(45,146,54)', 'rgb(66,169,224)', 'rgb(135,80,154)', 'rgb(51,70,151)', 'rgb(231,50,138)', 'rgb(227,18,20)'];
                    chart['tooltip'] = {
                        show: true,
                        backgroundColor: 'rgba(0, 0, 0, .8)'
                    };
                    chart['xAxis'] = [{
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    }];
                    chart['yAxis'] = [{
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    }];
                    chart['series'] = seriesHolder;

                    this.charts.push(chart)
        },
        err => {},
        () => {},
        )
    }



}
