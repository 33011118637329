import { Component, OnInit, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailSuccessComponent } from 'src/app/_partials/system/modals/email-success/email-success.component';
import { DownloadModelDetailsComponent } from 'src/app/_partials/system/modals/download-model-details/download-model-details.component';
import { DynamicFormModalComponent } from 'src/app/_partials/system/modals/dynamic-form-modal/dynamic-form-modal.component';

import { AuthService } from '../../../_services/auth.service';
import { environment } from '../../../../environments/environment';

import { Router, ActivatedRoute } from '@angular/router';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-model-list-breadcrumbs',
  templateUrl: './model-list-breadcrumbs.component.html',
  styleUrls: ['./model-list-breadcrumbs.component.scss']
})
export class ModelListBreadcrumbsComponent implements OnInit {

  @Input() contextType;
  @Input() modelId;
  @Input() modelInitData;
  public isCollapsed = false;
  userDetails;
  title;
  config;
  modelName;
  modelData;
  modelCount;


  constructor(private modalService: NgbModal, private authService: AuthService, private _data: CommonDataService) { }

  ngOnInit() {

    this.modelName = this.contextType;

    this.config = environment.dashboardConfig.modelSettings[this.modelName].partialInfoSetup;

    this.modelData = this.modelInitData;

  }

  openEmailModal() {
    const modalRef = this.modalService.open(EmailSuccessComponent);
    modalRef.componentInstance.data = {email: "brian@hellocavalry.com"};
  }

  openDownloadModal() {
    const modalRef = this.modalService.open(DownloadModelDetailsComponent);
    modalRef.componentInstance.data = {email: "brian@hellocavalry.com"};
  }

  openFormModal() {
    const modalRef = this.modalService.open(DynamicFormModalComponent);
    modalRef.componentInstance.data = {email: "brian@hellocavalry.com"};
  }

  getContextModel() {

  }

  getModelValueFromMappingKey( valueMappingKey) {

    //systemManagedEntities->paymentDetails->total

    var model = this.modelData;


    let objectPath = valueMappingKey.split('->');

    var objectName, sectionName, valueKey, value;

    if(objectPath[0]  && objectPath[1] ) {

      // value = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

      // if(objectPath[2]) {
      //   value = value[objectPath[2]];
      // }

      value = '';

      if(model['cover']['model']['modelData'].hasOwnProperty(objectPath[0]) ) {

        if(model['cover']['model']['modelData'][objectPath[0]].hasOwnProperty(objectPath[1])) {
            value = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

            // console.log(model);
      
            if(objectPath[2]) {
              value = value[objectPath[2]];
            }
        }

      }

    }
    
    if(value === '')
      value = 'N/A';

    return value;

  }

}
