// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost/Projects/ClipboardMasterAPI/bot-api/public/alpha',
  // fileUrl: 'http://localhost/Projects/ClipboardMasterAPI/bot-api/public/',
  // resourceLink: 'http://localhost/Projects/ClipboardMasterAPI/bot-api/public',

  // apiUrl: 'http://52.29.4.8/alpha',
  // fileUrl: 'http://52.29.4.8/',
  //  resourceLink: 'http://52.29.4.8/',

  apiUrl: 'http://3.123.117.58/alpha',
  fileUrl: 'http://52.29.4.8/',
  resourceLink: 'http://3.123.117.58/',

  

  dashboardConfig : {

      menu : {

        pages : [
            //example pages
          // {
          //   pageName : "View Users",
          //   pageDescription : "View all the users in the system",
          //   linkText : "Users",
          //   linkUrl: "dashboard/model-list/botUser",
          //   icon : "claims"
          // },

          {
            pageName : "View Units",
            pageDescription : "View Units",
            linkText : "Units",
            linkUrl: "dashboard/model-list/forensicEngineeringAssement",
            icon : "claims"
          },

          {
            pageName : "Final Summary Report",
            pageDescription : "View Summary",
            linkText : "Summary Report",
            linkUrl: "dashboard/summary-list",
            icon : "claims"
          },

          {
            pageName : "Summary Report Editor",
            pageDescription : "Edit Report",
            linkText : "Edit Reports",
            linkUrl: "dashboard/summary-editor",
            icon : "claims"
          },

          {
            pageName : "View Summary Stats",
            pageDescription : "View Units",
            linkText : "Summary Stats",
            linkUrl: "dashboard/clipboard/summary-stats",
            icon : "claims"
          },

          {
            pageName : "Import Units",
            pageDescription : "Import Units",
            linkText : "Import Units",
            linkUrl: "dashboard/upload-model/forensicEngineeringAssement",
            icon : "claims"
          }


        ]

      },

      modelSettings : {

        forensicEngineeringAssement : { 
          partialInfoSetup : {
               name : "forensicEngineeringAssement", //Name of the model to pull data from
               modelTitle: "House Unit",
               contextRelationShip: "oneToMany", //oneToOne , oneToMany
               status : "formFieldsEntities->houseDetails->status", //if model has no status flags available, leave empty
               fields: [
                  {
                    key : "Sys. Id",
                    value : "id"
                  },
                 {
                   key : "Township",
                   value : "formFieldsEntities->houseDetails->township" //example path in the json model to grab the value from. Starts with eith system object or form fields object
                 },
                 {
                   key : "Unit Type",
                   value : "formFieldsEntities->houseDetails->status"
                 },
                 
                 {
                   key : "Village",
                   value : "formFieldsEntities->houseDetails->village"
                 },
                 {
                  key : "ERF",
                  value : "formFieldsEntities->houseDetails->erf"
                },
             ]
           },
  
           accodionDisplayInfo : {
            title : "Forensic Engineering Assement",
            flagKey : "Unit Type",
            flagValue : "formFieldsEntities->houseDetails->status",
            formFields : true,
            formFieldObjects : {
                  '01' : {
                    title: "1.0 PLATFORM",
                    flagKey : "Unit Type",
                    flagValue : "formFieldsEntities->houseDetails->status",
                    createModel : true,
                    sections : [
                            {title : "House Details", section : "houseDetails", objectType: "formFieldsEntities"},
                            {title : "Platform Soil Clasification", section : "SoilClasification_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Soil Compaction", section : "SoilCompaction_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Soil Erosion", section : "SoilErosion_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Ponding", section : "Ponding_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Shaping Of Ground", section : "ShapingOfGround_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Impervious Layer", section : "ImperviousLayer_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Proximity Of Trees", section : "ProximityOfTrees_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Stability Of Embarkment Cut Side", section : "StabilityOfEmbarkmentCutSide_Platform", objectType: "formFieldsEntities"},
                            {title : "Platform Stability Of Embarkment Fill Side", section : "StabilityOfEmbarkmentFillSide_Platform", objectType: "formFieldsEntities"}
                        ],
                    
                  },
              
              
                  "02" : {
                    title: "2.0 FOUNDATION STRIP",
                    flagKey : "Unit Type",
                    flagValue : "formFieldsEntities->houseDetails->status",
                    sections : 
                        [
                          {title : "2.1  Depth of footings below ground level", section : "DepthOfFootings_FoundationStrip", objectType: "formFieldsEntities"},
                          {title : "2.2  Concrete condition", section : "concreteCondintion_FoundationStrip", objectType: "formFieldsEntities"},
                          {title : "2.3  Distress in foundation brickwork", section : "distressInFoundationBrickwork_FoundationStrip", objectType: "formFieldsEntities"},
                          {title : "2.4  Surface bed levelness", section : "surfaceBadLevelness_FoundationStrip", objectType: "formFieldsEntities"},
                          {title : "2.5  Brick force (for foundation wall >400mm)", section : "brickForce_FoundationStrip", objectType: "formFieldsEntities"}
                        ],
                    
                  },


                  "03" : 
                  {
                    title: "2.0 FOUNDATION RAFT",
                    sections : 
                        [
                          {title : "2.1  Depth of edge beam below ground level", section : "DepthOfEdge_FoundationRaft", objectType: "formFieldsEntities"},
                          {title : "2.2  Concrete condition", section : "concreteCondintion_FoundationRaft", objectType: "formFieldsEntities"},
                          {title : "2.3  Dimension tolerance", section : "dimensions_FoundationRaft", objectType: "formFieldsEntities"},
                          {title : "2.4  Brickwork overhang", section : "brickworkOverhaung_FoundationRaft", objectType: "formFieldsEntities"}
                        ],
                    
                    },


                  "04" : {
                    title: "4.0 FLOORS & STAIRS",
                    sections : 
                        [
                          {title : "4.1  Appearance of Screed or power floated", section : "appearanceOfScreed_FlooarsAndStairs", objectType: "formFieldsEntities"},
                          {title : "4.2  Height of floor above ground level", section : "heightOfFloorAboveGround_FlooarsAndStairs", objectType: "formFieldsEntities"},
                          {title : "4.3  Suspended Floors", section : "suspendedFloors_FlooarsAndStairs", objectType: "formFieldsEntities"},
                          {title : "4.4 Geometry of stairs", section : "geometryOfStairs_FlooarsAndStairs", objectType: "formFieldsEntities"},
                          {title : "4.5 Condition of Stairs and balustrades", section : "conditionOfStairsAndBalustrades_FlooarsAndStairs", objectType: "formFieldsEntities"},
                        ],
                    
                  },


                  "05" : {
                  title: "5.0 SUPERSTRUCTURE",
                  sections : 
                      [
                        {title : "5.1 DPC under brickwork", section : "dpcUnderBrickWork_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.2 Consistency of bed-joint & perpend thickness, to standards", section : "consistencyOfBedJoint_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.3 Wall plumbness & squareness", section : "wallPlumbness_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.4 Movement joints/Articulation", section : "movementOfJoints_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.5 Brickforce", section : "brickforce_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.6 Cracks (as per HBM)", section : "cracksAsHBM_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.7 Dampness", section : "dampness_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.8  External Wall Finishes", section : "externalwalls_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.9  Insertion of doors and window frames", section : "insertionOfDoorsAndWindows_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.10 Correct installation of window sills", section : "correctIntallationOfWindows_SuperStructure", objectType: "formFieldsEntities"},
                        {title : "5.11 Installation of Roof anchors", section : "installationOfRoofanchors_SuperStructure", objectType: "formFieldsEntities"}
                      ],
                  
                  },

                  "06" : {
                    title: "6.0 ROOF",
                    sections : 
                        [
                          {title : "6.1 Wallplate correctly installed", section : "wallplateCorrectlyInstalled_Roof", objectType: "formFieldsEntities"},
                          {title : "6.2 SABS approved timber members", section : "sabsAprovedTimber_Roof", objectType: "formFieldsEntities"},
                          {title : "6.3 Standard roof truss spacing/Rational design", section : "standardRoofTruss_Roof", objectType: "formFieldsEntities"},
                          {title : "6.4 Roof bracing", section : "roofBracing_Roof", objectType: "formFieldsEntities"},
                          {title : "6.5 Timber sealing/treatment", section : "timberSealingTreatment_Roof", objectType: "formFieldsEntities"},
                          {title : "6.6 Roof leaks", section : "roofLeaks_Roof", objectType: "formFieldsEntities"},
                          {title : "6.7 Underlay", section : "underLay_Roof", objectType: "formFieldsEntities"},
                          {title : "6.8 Beamfilling", section : "beamFilling_Roof", objectType: "formFieldsEntities"},
                          {title : "6.9 Roof coverings  and supports installed to specifications and in good condition", section : "roofCoveringAndSupports_Roof", objectType: "formFieldsEntities"},
                        ],
                    
                  },

                  "07" : {
                  title: "7.0 PLUMBING & ELECTRICAL",
                  sections : 
                      [
                        {title : "7.1 Plumbing installations", section : "plumbingInstallations_PlumbingAndElectrical", objectType: "formFieldsEntities"},
                        {title : "7.2 Signs of Leakages", section : "signsOfLeakages_PlumbingAndElectrical", objectType: "formFieldsEntities"},
                        {title : "7.3 Electrical Installations", section : "electricalInstalations_PlumbingAndElectrical", objectType: "formFieldsEntities", last_step : true},
        
                      ],
                    
                  }
            },
            sections: [
              {title : "House Details", section : "houseDetails", objectType: "formFieldsEntities"},
              {title : "Platform Soil Clasification", section : "SoilClasification_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Soil Compaction", section : "SoilCompaction_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Soil Erosion", section : "SoilErosion_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Ponding", section : "Ponding_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Shaping Of Ground", section : "ShapingOfGround_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Impervious Layer", section : "ImperviousLayer_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Proximity Of Trees", section : "ProximityOfTrees_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Stability Of Embarkment Cut Side", section : "StabilityOfEmbarkmentCutSide_Platform", objectType: "formFieldsEntities"},
              {title : "Platform Stability Of Embarkment Fill Side", section : "StabilityOfEmbarkmentFillSide_Platform", objectType: "formFieldsEntities"}
            ]
          },
  
          // files : {
          //   flagKey : "Platform Images",
          //   flagValue : "files->Platform->images",
          // },
  
          createModel : true,
          createModelSettings : {
            formGroups : [
              {
                title : "1.0 Platform Details",
                key : "Platform",
                sections : [
                  "houseDetails",
                  "SoilClasification_Platform",
                  "SoilCompaction_Platform",
                  "SoilErosion_Platform",                
                  "Ponding_Platform",
                  "ShapingOfGround_Platform",
                  "ImperviousLayer_Platform",
                  "ProximityOfTrees_Platform",
                  "StabilityOfEmbarkmentCutSide_Platform",
                  "StabilityOfEmbarkmentFillSide_Platform"
                ]
              }
            ],
            sections : [  
              {label : "House Details", key : "houseDetails", required : true},
              {label : "Platform Soil Clasification", key : "SoilClasification_Platform", required : true},
              {label : "Platform Soil Compaction", key : "SoilCompaction_Platform", required : true},
              {label : "Platform Soil Erosion", key : "SoilErosion_Platform", required : true},
              {label : "Platform Ponding", key : "Ponding_Platform", required : true},
              {label : "Platform Shaping Of Ground", key : "ShapingOfGround_Platform", required : true},
              {label : "Platform Impervious Layer", key : "ImperviousLayer_Platform", required : true},
              {label : "Platform Proximity Of Trees", key : "ProximityOfTrees_Platform", required : true},
              {label : "Platform Stability Of Embarkment Cut Side", key : "StabilityOfEmbarkmentCutSide_Platform", required : true},
              {label : "Platform Stability Of Embarkment Fill Side", key : "StabilityOfEmbarkmentFillSide_Platform", required : true}
            ]
          }
       }, 

         botUser : {

          partialInfoSetup : {
            name : "botUser", //Name of the model to pull data from
            modelTitle: "User",
            contextRelationShip: "oneToOne", //oneToOne , oneToMany
            status : "formFieldsEntities->PersonalDetails->first_name", //if model has no status flags available, leave empty
            fields: [
                {
                  key : "First Name",
                  value : "formFieldsEntities->PersonalDetails->first_name"
                },
                {
                  key : "Last Name",
                  value : "formFieldsEntities->PersonalDetails->last_name" //example path in the json model to grab the value from. Starts with eith system object or form fields object
                },
                {
                  key : "Email",
                  value : "formFieldsEntities->PersonalDetails->email"
                },
                {
                  key : "Phone",
                  value : "formFieldsEntities->PersonalDetails->phone"
                },
                {
                  key : "Location",
                  value : "formFieldsEntities->PersonalDetails->city"
                },
            ]  
          },

          accodionDisplayInfo : {
            title : "User Details",
            flagKey : "",
            flagValue : "",
            sections: [
              {title : "Personal Details", section : "PersonalDetails", objectType: "formFieldsEntities"}
            ]
          }

         },

         userContextModels : {
          models : [

          ]
        }


      },

      landingPageConfig : {

        actions : [
          {
            name : "Download All Unit BOQs",
            icon : "my_policy.svg",
            description : "Create BOQ Reports for all units",
            linkText : "Download Unit BOQs",
            linkUrl : "/dashboard/reports/Order",
            modal : true,
            modalName : "create_zip_units" //email, download, form
          },

          {
            name : "Download Summary & Consolidated Report",
            icon : "my_policy.svg",
            description : "Create and download summary report and a consolidated report.",
            linkText : "Download reports",
            linkUrl : "/dashboard/reports/user/seller",
            modal : true,
            modalName : "download" //email, download, form
          },

          {
            name : "Summary Report Editor ",
            icon : "my_policy.svg",
            description : "View and edit the units for the summary report.",
            linkText : "Edit report",
            linkUrl : "/dashboard/model-details",
            modal : false
          },
        ],

        recentModels : {
          //array of recent items to be displayed on the page, each model should list the keys and value to be displayed
          models: [
            'forensicEngineeringAssement'
          ],
        },

        dataTable : {
          models : []
        },

        components: {
            actions : true,
            recentModels : true,
            modelDataTable: true,
            graphLineBar: false,
            graphChartBar: false         
        }

      }

    
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
