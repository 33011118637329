import { Component, OnInit , Input, OnChanges, SimpleChanges} from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-model-data-summary-view',
  templateUrl: './model-data-summary-view.component.html',
  styleUrls: ['./model-data-summary-view.component.scss']
})
export class ModelDataSummaryViewComponent implements OnInit , OnChanges {

  @Input() modelDetailsName;
  @Input() modelId;
  @Input() modelInitData;

  modelData;
  config;
  modelName;

  constructor() { }

  ngOnInit() {

    this.modelName = this.modelDetailsName;
    this.config = environment.dashboardConfig.modelSettings[this.modelName].partialInfoSetup;
    this.modelData = this.modelInitData;
  }

  getModelValueFromMappingKey( valueMappingKey) {

    //systemManagedEntities->paymentDetails->total

    var model = this.modelData;


    let objectPath = valueMappingKey.split('->');

    var objectName, sectionName, valueKey, value;

    if(objectPath[0]  && objectPath[1] ) {

      // value = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

      // if(objectPath[2]) {
      //   value = value[objectPath[2]];
      // }

      value = '';

      if(model['cover']['model']['modelData'].hasOwnProperty(objectPath[0]) ) {

        if(model['cover']['model']['modelData'][objectPath[0]].hasOwnProperty(objectPath[1])) {
            value = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

            // console.log(model);
      
            if(objectPath[2]) {
              value = value[objectPath[2]];
            }
        }

      }

    }
    
    if(value === '')
      value = 'N/A';

    return value;

  }

  ngOnChanges(changes: SimpleChanges) {
      // only run when property "data" changed
      if (changes['modelInitData']) {
      
          this.modelData = changes['modelInitData'].currentValue;
      }
  }


  //functionality not for product and should be removed

  viewModelReport() {

    var model = this.modelData['cover']['model']['modelData'];

    const form = window.document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", environment.apiUrl+"/view_PDF");
        //use _self to redirect in same tab, _blank to open in new tab
        form.setAttribute("target", "_blank"); 
        // form.setAttribute("target", "_self"); 
  
        form.appendChild(this.createHiddenElement('modelName', "thi"));
        form.appendChild(this.createHiddenElement('data', JSON.stringify(model)));
        window.document.body.appendChild(form);
  
        //console.log(form);
        form.submit();

  }

  createHiddenElement(name, value)  {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }

}
