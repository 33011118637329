import { Component, OnInit, Output, AfterViewInit  } from '@angular/core';
import { Router  , ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {AlertService} from 'ngx-alerts';

@Component({
  selector: 'app-system-file-import',
  templateUrl: './system-file-import.component.html',
  styleUrls: ['./system-file-import.component.scss']
})
export class SystemFileImportComponent implements OnInit, AfterViewInit {

  pageReady = true;
  modelName;

  constructor(private route: ActivatedRoute, private alertService: AlertService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {

      if(params['modelName']) {
        // this.modelName = params['modelName'];
        if(this.modelName != params['modelName']) {
          this.modelName = params['modelName'];
        }
      } 
    });

    
  }

  ngAfterViewInit() {
    this.pageReady = true;
  }

}
