import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-context-model-item-detial-link',
  templateUrl: './context-model-item-detial-link.component.html',
  styleUrls: ['./context-model-item-detial-link.component.scss']
})
export class ContextModelItemDetialLinkComponent implements OnInit {

  @Input() model;

  constructor() { }

  ngOnInit() {
  }

}
