import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailSuccessComponent } from 'src/app/_partials/system/modals/email-success/email-success.component';
import { DownloadModelDetailsComponent } from 'src/app/_partials/system/modals/download-model-details/download-model-details.component';
import { DynamicFormModalComponent } from 'src/app/_partials/system/modals/dynamic-form-modal/dynamic-form-modal.component';

import { AuthService } from '../../../_services/auth.service';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-actions-accordion',
  templateUrl: './actions-accordion.component.html',
  styleUrls: ['./actions-accordion.component.scss']
})
export class ActionsAccordionComponent implements OnInit {

  public isCollapsed = false;
  greeting;
  userDetails;
  name = "";
  actionsConfig = environment.dashboardConfig.landingPageConfig.actions;

  constructor(private modalService: NgbModal, private authService: AuthService, private router: Router, private httpClient: HttpClient) { }

  ngOnInit() {

    this.getTimeOfDay();
    this.userDetails = this.authService.getSessionDetails('PersonalDetails');

    if(this.userDetails.first_name) {
      this.name = this.userDetails.first_name;
    }
    
  }

  actionBoxAction(item : any, idx) {
      if(item.modal) {
          switch(item.modalName) {

            case "email": {
                this.openEmailModal();
                break; 
            }

            case "download": {
                this.openDownloadModal();
                break; 
            }

            case "form": {
                this.openFormModal();  
                break;            
            }

            //custom action
            case "create_zip_units": {
                this.createAllZipUnits(1, idx);  
                break;            
            }

            default: { 
              //statements; 
              break; 
            } 

          }
      }

      else {
        this.router.navigate([item.linkUrl]);
      }
  }

  //Report Action Modals

  openEmailModal() {
    const modalRef = this.modalService.open(EmailSuccessComponent);
    modalRef.componentInstance.data = {email: "brian@hellocavalry.com"};
  }

  openDownloadModal() {
    const modalRef = this.modalService.open(DownloadModelDetailsComponent);
    modalRef.componentInstance.data = {email: "brian@hellocavalry.com"};
  }

  openFormModal() {
    const modalRef = this.modalService.open(DynamicFormModalComponent);
    modalRef.componentInstance.data = {email: "brian@hellocavalry.com"};
  }

  getTimeOfDay() {
    var now  = new Date(),
    hour = now.getHours();

    var shift   = "Good ";
        shift  += (hour >= 4  && hour <= 11) ? "morning" : "",
        shift  += (hour >= 12 && hour <= 16) ? "afternoon" : "",
        shift  += (hour >= 17 && hour <= 20) ? "evening" : "",
        shift  += (hour >= 21 || hour <= 3) ?  "night" : "";

    this.greeting = shift;
  }

  

  createAllZipUnits(page,idx) {
    //this.btn2Text = "Please wait...";
    var auth_headers = {};

    this.actionsConfig[idx]['linkText'] = "Please wait...";
    
    //return true;

    let apikey = localStorage.getItem('apikey');
    let authkey = localStorage.getItem('authkey');

    auth_headers["apikey"] = apikey;
    auth_headers["authkey"] = authkey;

    this.httpClient.post(environment.apiUrl+"/create_all_units_zip",
          {
              "auth_headers": auth_headers,
              "pageNumber" : page
          })
          .subscribe(
              data => {
                if(data["pdf"]) {
                  // this.buttonText = "Generate Reports Again";

                  if(page == 13) {
                    this.b64pdf(idx)
                  }

                  else {
                    this.createAllZipUnits(page+1, idx)
                  }

                  
                }
              },
              error => {
                  console.log("Error", error);
              },

              () => {


              }
          ); 
  }

  b64pdf(idx) {

    this.httpClient.post(environment.apiUrl+"/zip_units_together",
    {

    })
    .subscribe(
        data => {
          if(data["pdf"]) {
            // this.buttonText = "Generate Reports Again";

            // Insert a link that allows the user to download the PDF file
            var link = document.createElement('a');
            link.innerHTML = 'Download PDF file';
            link.download = 'file.pdf';
            link.href = environment.resourceLink+'/tmpuploads/units.zip';
            link.setAttribute("target", "_blank"); 
            document.body.appendChild(link);
            link.click();

            this.actionsConfig[idx]['linkText'] = 'Download Unit BOQs';

            
          }
        },
        error => {
            console.log("Error", error);
        },

        () => {


        }
    );


      
  }

}
