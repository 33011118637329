import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../../../environments/environment';
import { CommonDataService } from '../../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-download-model-details',
  templateUrl: './download-model-details.component.html',
  styleUrls: ['./download-model-details.component.scss']
})
export class DownloadModelDetailsComponent implements OnInit {

  @Input() data;

  buttonText = "Generate Reports";
  btn2Text = "Ditsobotla Consolidated";

  filterOptions =  [
    {
      "id": "RDP House",
      "value": "RDP House"
    },
    {
        "id": "Not Assessed/Locked",
        "value": "Not Assessed/Locked",
        "answerActions" : {
            "endFormDataCapture" : true
        }
    },
    {
        "id": "Extended Unit",
        "value": "Extended Unit"
    },

    {
      "id": "Unbuilt Site(No RDP)",
      "value": "Unbuilt Site(No RDP)",
      "answerActions" : {
          "endFormDataCapture" : true
      }
    },

    {
      "id": "Incomplete House Built",
      "value": "Incomplete House Built"
    },

    {
      "id": "Rebuilt by Owner",
      "value": "Rebuilt by Owner",
      "answerActions" : {
          "endFormDataCapture" : true
      }
    },

    {
      "id": "Other Building/Structure",
      "value": "Other Building/Structure",
      "answerActions" : {
          "endFormDataCapture" : true
      }
    },
    
    {
      "id": "Rebuild(Damaged Unit)",
      "value": "Rebuild(Damaged Unit)"
    }


  ];

  constructor(public activeModal: NgbActiveModal, private httpClient: HttpClient) {}

  ngOnInit() {
  }

  viewModelReport() {

    var auth_headers = {};

    let apikey = localStorage.getItem('apikey');
    let authkey = localStorage.getItem('authkey');

    auth_headers["apikey"] = apikey;
    auth_headers["authkey"] = authkey;

    const form = window.document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", environment.apiUrl+"/view_summary_report");
        //use _self to redirect in same tab, _blank to open in new tab
        form.setAttribute("target", "_blank"); 
        // form.setAttribute("target", "_self"); 
  
        form.appendChild(this.createHiddenElement('modelName', "thi"));
        form.appendChild(this.createHiddenElement('auth_headers', JSON.stringify(auth_headers)));
        window.document.body.appendChild(form);
  
        //console.log(form);
        form.submit();

  }

  createHiddenElement(name, value)  {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }

  createDownloadLink() {
    this.buttonText = "Please wait...";
    var auth_headers = {};

    let apikey = localStorage.getItem('apikey');
    let authkey = localStorage.getItem('authkey');

    auth_headers["apikey"] = apikey;
    auth_headers["authkey"] = authkey;

    this.httpClient.post(environment.apiUrl+"/view_summary_report",
          {
              "auth_headers": auth_headers,
          })
          .subscribe(
              data => {
                if(data["pdf"]) {
                  //this.buttonText = "Download Report";

                  this.createSlimBOQTotals()
                }
              },
              error => {
                  console.log("Error", error);
              },

              () => {


              }
          ); 
  }

  createSlimBOQTotals() {
    //this.btn2Text = "Please wait...";
    var auth_headers = {};

    let apikey = localStorage.getItem('apikey');
    let authkey = localStorage.getItem('authkey');

    auth_headers["apikey"] = apikey;
    auth_headers["authkey"] = authkey;

    this.httpClient.post(environment.apiUrl+"/view_summary_report_slim",
          {
              "auth_headers": auth_headers,
          })
          .subscribe(
              data => {
                if(data["pdf"]) {
                  this.buttonText = "Generate Reports Again";

                  this.b64pdf()
                }
              },
              error => {
                  console.log("Error", error);
              },

              () => {


              }
          ); 
  }

  b64pdf() {


      // Insert a link that allows the user to download the PDF file
      var link = document.createElement('a');
      link.innerHTML = 'Download PDF file';
      link.download = 'file.pdf';
      link.href = environment.resourceLink+'/tmpuploads/reports.zip';
      link.setAttribute("target", "_blank"); 
      document.body.appendChild(link);
      link.click();
  }

}
