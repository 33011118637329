import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource} from '@angular/material';

import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import {AlertService} from 'ngx-alerts';

// declare let jsPDF;
// declare let html2canvas;

@Component({
  selector: 'app-custom-summary-table',
  templateUrl: './custom-summary-table.component.html',
  styleUrls: ['./custom-summary-table.component.scss']
})
export class CustomSummaryTableComponent implements OnInit {

  highlightedRows = [];
  copyContentBtnText = "Copy Content";
  masterRow = "";

  displayedColumns: string[] = [
    'erf', 
    'status', 
    'comments', 
    'total_cost', 
    'biqh_score', 
    'summaryImages1',
    'summaryImages2',
    'summaryImages3',
    'id',
    // 'summaryImages5',
  ];
  exampleDatabase: ExampleHttpDatabase | null;
 data: SummaryReport[] = [];

 //dataSource = new MatTableDataSource();

  public dataSource = new MatTableDataSource<SummaryReport>();

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageReady = false;
  pageSize = 20;

  fileUrl = environment.fileUrl;

  selection = new SelectionModel<SummaryReport>(true, []);


  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {}) sort: MatSort;

  constructor(private _httpClient: HttpClient, private route: ActivatedRoute, private alertService: AlertService) {}

  ngOnInit() {

    this.route.params.subscribe((params) => {
      if(params['PageNumber']) {

          this.paginator.pageIndex = parseInt(params['PageNumber']);
     
      }
    });

  }

//   async  downloadPdf() {

//     let doc = new jsPDF();

//     // Add a title to your PDF
//     doc.setFontSize(30); 
//     doc.text(12, 10, "Your Title");

//     // Create your table here (The dynamic table needs to be converted to canvas).
//     let element = <HTMLScriptElement>document.getElementsByClassName("example-container") 
//     [0];
//     html2canvas(element)
//     .then((canvas: any) => {
//     doc.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 50, 
//     doc.internal.pageSize.width, element.offsetHeight / 5 );
//     doc.save(`Report-${Date.now()}.pdf`); })

// }

getTextArea(array) {
  return array.join("\n")
}

applyFilter(filterValue: string) {
  this.dataSource.filter = filterValue.trim().toLowerCase();
  this.resultsLength = this.dataSource.data.length;
  // this.resultsLength = this.dataSource.length;
}

downLoadPdf() {

  console.log(this.data);

    const a = document.body.appendChild(document.createElement('a'));
    const file = new Blob([JSON.stringify(this.data)], {
      type: 'application/json'
    });
    a.href = URL.createObjectURL(file);
    a.download = 'summaryJson.json';
    a.click();
    document.removeChild(a);
    
}

  deleteModel(id) {
    //alert(id);
      this._httpClient.post(environment.apiUrl+"/delete",
            {
                "modelName": "forensicEngineeringAssement",
                "id" : id
            })
            .subscribe(
                data => {
                    //console.log("POST Request is successful ", data);
                    this.pageReady = true;
                },
                error => {
                    console.log("Error", error);
                },

                () => {
                }
            ); 
      

  }

  duplicateContent() {
    //this.highlightedRows = [];
    

    if(this.copyContentBtnText == "Copy Content") {
      this.masterRow = "";
      if(this.highlightedRows.length == 1) {
        
        this.masterRow = this.highlightedRows[0];
        this.alertService.warning('Now select the rows you want this content to be applied to, click Paste Content when done!');
        this.highlightedRows = [];
        this.copyContentBtnText = "Paste Content"
      }

      else {
        this.alertService.warning('First Select 1 row that you want to copy content from!');
        this.highlightedRows = [];
      }
    }

    else {

      if(this.masterRow != "" && this.highlightedRows.length > 0) {
        //alert('merging')
        this.copyContentBtnText = "Please Wait...";

        this._httpClient.post(environment.apiUrl+"/copy_paste_model",
            {
                "modelName": "forensicEngineeringAssement",
                "masterRow" : this.masterRow,
                "affectedRows" : this.highlightedRows

            })
            .subscribe(
                data => {
                    //console.log("POST Request is successful ", data);
                    this.pageReady = true;
                    this.highlightedRows = [];
                    this.alertService.success('Rows have been updated with new data!');

                    this.copyContentBtnText = "Copy Content";
                },
                error => {
                    console.log("Error", error);
                    this.alertService.danger('Whoops, something went wrong. Changes not effected!');

                    this.copyContentBtnText = "Copy Content";
                },

                () => {
                }
            );
      }

    }

  }


  flagHighlightedRows(id) {
    //alert(id);
    this._httpClient.post(environment.apiUrl+"/flag_model",
    {
        "modelName": "forensicEngineeringAssement",
        "mids" : this.highlightedRows
    })
    .subscribe(
        data => {
            //console.log("POST Request is successful ", data);
            this.pageReady = true;
            this.highlightedRows = [];
            // alert("Rows have been flagged")
            this.alertService.success('Rows have been flagged!');
        },
        error => {
            console.log("Error", error);
        },

        () => {
        }
    ); 
      

  }

  deleteHighlightedRows() {

    this.pageReady = false;

    this.highlightedRows.forEach(id => {
      this.deleteModel(id);
    });

    this.pageReady = true;
    this.highlightedRows = [];
    alert("Rows have been deleted")
  }

  mergeHighlightedRows() {
    this._httpClient.post(environment.apiUrl+"/merge_conflicts",
            {
                "modelName": "forensicEngineeringAssement",
                "mids" : this.highlightedRows
            })
            .subscribe(
                data => {
                    //console.log("POST Request is successful ", data);
                    this.pageReady = true;
                    this.highlightedRows = [];
                    //alert("Rows have been merged")
                    this.alertService.success('Rows have been merged!');
                },
                error => {
                    console.log("Error", error);
                },

                () => {
                }
            ); 
  }

  highlightRowsSelected(row) {

    if(this.highlightedRows.includes(row.id) ) {
      //delete this.highlightedRows[row.id];

      const index = this.highlightedRows.indexOf(row.id, 0);
      if (index > -1) {
        this.highlightedRows.splice(index, 1);
      }

    }

    else {
      this.highlightedRows.push(row.id)
    }
    
    console.log(this.highlightedRows)
  }


  ngAfterViewInit() {
    this.exampleDatabase = new ExampleHttpDatabase(this._httpClient);

    // If the user changes the sort order, reset back to the first page.
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);


    merge(this.sort.sortChange, this.paginator.page)
    // merge(this.sort.sortChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.pageReady = false;
          return this.exampleDatabase!.getRepoIssues(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.pageSize);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          //this.resultsLength = data.total_count;
          this.pageReady = true;

          this.resultsLength = data.items.length;

          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.pageReady = true;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {this.data =  data;  this.dataSource = new MatTableDataSource(data);});
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.data.length;
      return numSelected === numRows;
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SummaryReport): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.erf}`;
    }


}

export interface ReportApi {
  items: SummaryReport[];
  total_count: number;
}

export interface SummaryReport {
  erf: string;
  status: string;
  comments: {};
  total_cost: string;
  biqh_score: string;
  summaryImages1: {};
  summaryImages2: {};
  summaryImages3: {};
  id: string;
  // summaryImages4: string;
  // summaryImages5: string;

}

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDatabase {
  constructor(private _httpClient: HttpClient) {}

  getRepoIssues(sort: string, order: string, page: number, size : number): Observable<ReportApi> {
    const href = environment.apiUrl+'/create_summary_report_json';
    const requestUrl =`${href}`;

    //return this._httpClient.get<GithubApi>(requestUrl);

    return this._httpClient.post<any>(`${environment.apiUrl}/`+'create_summary_report_json', {"current_page": page, "size" : size})

    //this._httpClient.post(environment.apiUrl+"/api-login");

  }
}
