import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-item-history-summary',
  templateUrl: './item-history-summary.component.html',
  styleUrls: ['./item-history-summary.component.scss']
})
export class ItemHistorySummaryComponent implements OnInit {

  @Input() modelDetailsName;
  modelData;
  recentCount = 3;
  config;

  modelName;

  @Output () notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _data: CommonDataService, private router: Router) { }

  ngOnInit() {
      // console.log(this.modelConfig)
      // this.config = this.modelConfig;

      this.modelName = this.modelDetailsName;

      this.config = environment.dashboardConfig.modelSettings[this.modelName].partialInfoSetup;

      var data = this.queryModelFromApi(this.config);
  }

  getModelData(config) {

  }

  queryModelFromApi(model) {

    let queryData = new FormData();
    queryData.append('modelName', model.name);
    queryData.append('limit', '3');

    if(model.hasOwnProperty("queryExtras")) {

      for (const [objectKey, queryObject] of Object.entries(model.queryExtras)) { 
        queryData.append(queryObject['key'] , queryObject['value']);
      }

    }

    this._data.getModelData(queryData) 
    .pipe(first())
    .subscribe(

      data => {
        //console.log(data);
        this.modelData = data['covers'];

      },

      error => {
        console.log(error);
        //   iziToast.success({
        //     title: 'Error!',
        //     position: 'topRight',
        //     icon: 'icon-circle-check',
        //     message: 'Failed to retrieve profile info.'
        // });
        this.notify.emit('ready');

      },

      () => {
        this.notify.emit('ready');
          return this.modelData;
      }
    );

  }

  getModelValueFromMappingKey(currentModelIndex, valueMappingKey) {

    //systemManagedEntities->paymentDetails->total

    var model = this.modelData[currentModelIndex];

    if(valueMappingKey == 'id') {
      return String(model['id']);
    }

    if(valueMappingKey == 'created') {

      var currentDate = new Date(model['created']*1000);

      var date = currentDate.getDate();
      var month = currentDate.getMonth(); //Be careful! January is 0 not 1
      var year = currentDate.getFullYear();

      var dateString = date + "-" +(month + 1) + "-" + year;
      return String(dateString);
    }

    if(valueMappingKey == 'last_updated') {
      var currentDate = new Date(model['last_updated']*1000);

      var date = currentDate.getDate();
      var month = currentDate.getMonth(); //Be careful! January is 0 not 1
      var year = currentDate.getFullYear();

      var dateString = date + "-" +(month + 1) + "-" + year;
      return String(dateString);
    }


    let objectPath = valueMappingKey.split('->');

    var objectName, sectionName, valueKey, value;

    if(objectPath[0]  && objectPath[1] ) {

      // value = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

      // if(objectPath[2]) {
      //   value = value[objectPath[2]];
      // }

      value = '';

      if(model['cover']['model']['modelData'].hasOwnProperty(objectPath[0]) ) {

        if(model['cover']['model']['modelData'][objectPath[0]].hasOwnProperty(objectPath[1])) {
            value = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

            // console.log(model);
      
            if(objectPath[2]) {
              value = value[objectPath[2]];
            }
        }

      }

    }
    
    if(value === '')
      value = 'N/A';

    return value;

  }

  loadMore(modelName: String) {
    this.router.navigate(['/dashboard/model-list/'+modelName]);
  }

  private createHiddenElement(name: string, value: string): HTMLInputElement {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }

  loadModelDetails(modelName: String, id ) {

    if(this.config['button'] !== undefined) {

      if(this.config['button']['action'] == 'resorce-link') {

        const form = window.document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", environment.apiUrl + this.config['button']['resorce_link']);
        //use _self to redirect in same tab, _blank to open in new tab
        form.setAttribute("target", "_blank"); 
        // form.setAttribute("target", "_self"); 
  
        form.appendChild(this.createHiddenElement('modelName', this.modelName));
        form.appendChild(this.createHiddenElement('mid', id));
        window.document.body.appendChild(form);
  
        //console.log(form);
        form.submit();
      }

      else {
        let url = '/dashboard/model-details/'+this.modelName+'/'+id;
        this.router.navigate([url]);
      }
    }

    else
      this.router.navigate(['/dashboard/model-details/'+modelName+'/'+id]);
  }

}
