import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {DemoMaterialModule} from './material-module';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FlyoutModule } from 'ngx-flyout';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';

//modules
import { DataTableModule } from './modules/data-table/data-table.module';
import { DynamicFormModule } from './modules/dynamic-form/dynamic-form.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { AppRoutingModule } from './app-routing.module';

//form modukes
//needed modules
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AuthInterceptor } from './_interceptors/auth.interceptor';
import { AuthGuard } from './_services/auth.guard';

//services
import { ValidatorService } from './_services/validator.service';
import { AuthService } from './_services/auth.service';
import { CommonDataService } from './_services/common-data.service';

import { AppComponent } from './app.component';
import { MainLayoutComponent } from './view-components/main-layout/main-layout.component';
import { ScreenContentComponent } from './view-components/screen-content/screen-content.component';
import { SideBarMenuComponent } from './view-components/side-bar-menu/side-bar-menu.component';
import { HeaderSectionComponent } from './view-components/header-section/header-section.component';
// import { DashboardScreenComponent } from './system-pages/layouts/dashboard-screen/dashboard-screen.component';

import { SassHelperComponent } from './_providers/sass-helper/sass-helper';
import { ActionsAccordionComponent } from './_partials/system/actions-accordion/actions-accordion.component';
import { ModelsAccordionsComponent } from './_partials/system/models-accordions/models-accordions.component';
import { ContextBreadcrumbsComponent } from './_partials/system/context-breadcrumbs/context-breadcrumbs.component';
import { FilesSectionComponent } from './_partials/system/files-section/files-section.component';
import { ModelDataSummaryViewComponent } from './_partials/system/model-data-summary-view/model-data-summary-view.component';
import { ItemHistorySummaryComponent } from './_partials/system/item-history-summary/item-history-summary.component';
import { ModelDetailsWithHistoryComponent } from './system-pages/application-pages/model-details-with-history/model-details-with-history.component';
import { DashboardLandingPageComponent } from './system-pages/application-pages/dashboard-landing-page/dashboard-landing-page.component';
import { UserContextModelsComponent } from './system-pages/application-pages/user-context-models/user-context-models.component';
import { EmailModelDetailsComponent } from './_partials/system/modals/email-model-details/email-model-details.component';
import { DownloadModelDetailsComponent } from './_partials/system/modals/download-model-details/download-model-details.component';
import { DynamicFormModalComponent } from './_partials/system/modals/dynamic-form-modal/dynamic-form-modal.component';
import { EmailSuccessComponent } from './_partials/system/modals/email-success/email-success.component';
import { BlankLayoutComponent } from './view-components/blank-layout/blank-layout.component';
import { LoginPageComponent } from './system-pages/auth-pages/login-page/login-page.component';
import { ModelListViewComponent } from './system-pages/application-pages/model-list-view/model-list-view.component';
import { ModelListBreadcrumbsComponent } from './_partials/system/model-list-breadcrumbs/model-list-breadcrumbs.component';
import { SpinnerCircleComponent } from './_partials/loaders/spinner-circle/spinner-circle.component';
import { ContextModelItemDetialLinkComponent } from './_partials/system/context-model-item-detial-link/context-model-item-detial-link.component';
import { FormWizardComponent } from './_partials/system/form-wizard/form-wizard.component';
import { AddModelComponent } from './system-pages/application-pages/add-model/add-model.component';
import { AppDragDropDirective } from './_partials/directives/app-drag-drop.directive';
import { SystemFileImportComponent } from './system-pages/application-pages/system-file-import/system-file-import.component';
import { FileImporterFormComponent } from './_partials/system/file-importer-form/file-importer-form.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AlertModule } from 'ngx-alerts';
import { CustomSummaryTableComponent } from './client/_partials/custom-summary-table/custom-summary-table.component';
import { SummaryPageComponent } from './client/pages/summary-page/summary-page.component';
import { SummaryTableEditorComponent } from './client/_partials/summary-table-editor/summary-table-editor.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    ScreenContentComponent,
    SideBarMenuComponent,
    HeaderSectionComponent,
    // DashboardScreenComponent,
    SassHelperComponent,
    ActionsAccordionComponent,
    ModelsAccordionsComponent,
    ContextBreadcrumbsComponent,
    FilesSectionComponent,
    ModelDataSummaryViewComponent,
    ItemHistorySummaryComponent,
    ModelDetailsWithHistoryComponent,
    DashboardLandingPageComponent,
    UserContextModelsComponent,
    EmailModelDetailsComponent,
    DownloadModelDetailsComponent,
    DynamicFormModalComponent,
    EmailSuccessComponent,
    BlankLayoutComponent,
    LoginPageComponent,
    ModelListViewComponent,
    ModelListBreadcrumbsComponent,
    SpinnerCircleComponent,
    ContextModelItemDetialLinkComponent,
    FormWizardComponent,
    AddModelComponent,
    AppDragDropDirective,
    SystemFileImportComponent,
    FileImporterFormComponent,
    CustomSummaryTableComponent,
    SummaryPageComponent,
    SummaryTableEditorComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    FlyoutModule,
    DataTableModule,
    DynamicFormModule,
    DemoMaterialModule,
    AnalyticsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    NgxEchartsModule,
    TagInputModule, 
    BrowserAnimationsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    AlertModule.forRoot({maxMessages: 5, timeout: 5000, position: 'right'})
  ],
  providers: [
    ValidatorService,
    AuthService,
    CommonDataService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  entryComponents: [EmailModelDetailsComponent,DownloadModelDetailsComponent,DynamicFormModalComponent,EmailSuccessComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
