import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';

import { DynamicFormModalComponent } from 'src/app/_partials/system/modals/dynamic-form-modal/dynamic-form-modal.component';

@Component({
  selector: 'app-models-accordions',
  templateUrl: './models-accordions.component.html',
  styleUrls: ['./models-accordions.component.scss']
})
export class ModelsAccordionsComponent implements OnInit , OnChanges {
  selected: any;
  closeResult: string;

  endpoint = "api-update-model-data";

  @Input() modelDetailsName;
  @Input() modelId;
  @Input() modelInitData;
  modelData;
  config;
  accodionSettings;
  modelName;
  modelFormTemplate;

  modelCount;
  modelQueryId;
  files = false;
  sectionFiles = {};

  dataReady =false;

  formFieldObjects = null;
  formGroupsWithPopulatedSections = [];
  selectedGroupKey = [];

  submitLinkText = "Edit details";

  constructor(private _data: CommonDataService, private modalService: NgbModal) { }

  ngOnInit() {
    // console.log(this.modelConfig)
    // this.config = this.modelConfig;

    this.modelName = this.modelDetailsName;
    this.modelQueryId = this.modelId;

    this.config = environment.dashboardConfig.modelSettings[this.modelName].partialInfoSetup;
    this.accodionSettings = environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo;


    if(this.accodionSettings.formFields) {
       
      //console.log(this.formFieldObjects)
      
      this.formFieldObjects = environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFieldObjects;

      //lets check which form groups to add that have filled in sections
      
      this.accodionSettings= this.accodionSettings.formFieldObjects['01'];
       
      this.selectedGroupKey[0] = this.accodionSettings.sections[0].section;
    } 

    if(environment.dashboardConfig.modelSettings[this.modelName].files) {
      this.files = environment.dashboardConfig.modelSettings[this.modelName].files;
    }
      

    var data;

    this.selected = this.accodionSettings.sections[0].section;

    this.createModelForm(this.config, 0);

    if(this.modelInitData && this.modelInitData.cover) {
      
      this.modelData = this.modelInitData;

      if(environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFields) {

        for (const [grpIndex, grpObject] of Object.entries(this.formFieldObjects )) {
          let add = false;

          console.log(grpObject)

          for (const [indexKey, sectionObject] of Object.entries(grpObject['sections'] )) {
  
            let myObj = this.modelData['cover']['model']['modelData']['formFieldsEntities'];

            console.log(myObj)
  
            if(myObj.hasOwnProperty(sectionObject['section'])) {
              add = true;
 
              //lets check if this section has any associated files
              if(this.modelData['cover']['model']['modelData']['formFieldsEntities'].hasOwnProperty('files')) {

                let imagesKey = 'images-'+sectionObject['section'];
                if(this.modelData['cover']['model']['modelData']['formFieldsEntities']['files'].hasOwnProperty(imagesKey)) {

                  let imageArray = this.modelData['cover']['model']['modelData']['formFieldsEntities']['files'][imagesKey];

                  if(typeof imageArray != "undefined" && imageArray != null && imageArray.length != null
                  && imageArray.length > 0) {

                    this.sectionFiles[sectionObject['section']] = imageArray;

                    console.log(this.sectionFiles);
                    //alert('hahahaha');

                  }

                }
                
              }
            }
  
  
          }

          if(add === true)
            this.formGroupsWithPopulatedSections.push(grpObject);
  
        }
      }
    }

    else
      data = this.queryModelFromApi(this.config);

    
}

createModelForm (config, key) {

  let queryData = new FormData();
  queryData.append('modelName', config.name);

  if(environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFields)
    queryData.append('objectKeys', this.getFormGroupSelected(key));

  else
    queryData.append('objectKeys', this.getSelected());

  this._data.createModelForm(queryData) 
  .pipe(first())
  .subscribe(

    data => {
      
      this.modelFormTemplate = data['definitionObjectForm'][config.name];

      console.log(this.modelFormTemplate)

    },

    error => {
      console.log(error);

    },

    () => {
      this.submitLinkText = "Edit details";
    }
  );
}

queryModelFromApi(model) {

  let queryData = new FormData();
  queryData.append('modelName', model.name);

  if(parseInt(this.modelQueryId) > 0) {
    queryData.append('mid', this.modelQueryId);
  }

  this._data.getModelData(queryData) 
  .pipe(first())
  .subscribe(

    data => {
      
      
      this.modelCount = Object.keys(data['covers']).length;
      this.modelData = data['covers'][this.modelCount - 1];

      this.dataReady = true;

      //console.log(this.modelData);

    },

    error => {
      console.log(error);
      //   iziToast.success({
      //     title: 'Error!',
      //     position: 'topRight',
      //     icon: 'icon-circle-check',
      //     message: 'Failed to retrieve profile info.'
      // });

    },

    () => {


        if(environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFields) {
          for (const [grpIndex, grpObject] of Object.entries(this.formFieldObjects )) {

            let add = false;
            console.log(grpObject)
            for (const [indexKey, sectionObject] of Object.entries(grpObject['sections'] )) {
    
              let myObj = this.modelData['cover']['model']['modelData']['formFieldsEntities'];
  
              console.log(myObj)
    
              if(myObj.hasOwnProperty(sectionObject['section'])) {
                add = true;

                  //lets check if this section has any associated files
                  if(this.modelData['cover']['model']['modelData']['formFieldsEntities'].hasOwnProperty('files')) {

                    let imagesKey = 'images-'+sectionObject['section'];
                    if(this.modelData['cover']['model']['modelData']['formFieldsEntities']['files'].hasOwnProperty(imagesKey)) {

                      let imageArray = this.modelData['cover']['model']['modelData']['formFieldsEntities']['files'][imagesKey];

                      if(typeof imageArray != "undefined" && imageArray != null && imageArray.length != null
                      && imageArray.length > 0) {

                        this.sectionFiles[sectionObject['section']] = imageArray;

                        console.log(this.sectionFiles);
                        //alert('hahahaha');

                      }

                    }
                    
                  }
              }
    
    
            }

            if(add)
              this.formGroupsWithPopulatedSections.push(grpObject);
    
          }


        }
        
      
        return this.modelData;
    }
  );

}

  select(item) {
    this.selected = item;
    this.createModelForm(this.config, 0);
  }

  formGroupsSelect(group, key) {
      this.selectedGroupKey[group] = key;
      this.createModelForm(this.config, group);
  }

  getFormGroupSelected(group) {
    console.log(this.selectedGroupKey[group])
    return this.selectedGroupKey[group];
  }

  isActiveFormGrpup(group , key) {
    return this.selectedGroupKey[group] == key;
  }

  isActive(item) {
    // alert(item)
      return this.selected == item;
  }

  getSelected() {
    return this.selected
  }

  switchFormFieldSection(group) {

    let key = group + 1;
    key = "0"+key;
    this.accodionSettings= environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFieldObjects[key];

    //this.selectedGroupKey[group] = this.accodionSettings.sections[0].section;

    this.createModelForm(this.config, group);
  }

  getModelValueFromMappingKey( valueMappingKey) {

    //systemManagedEntities->paymentDetails->total

    var model = this.modelData;


    let objectPath = valueMappingKey.split('->');

    var objectName, sectionName, valueKey;
    var value = '';

    if(objectPath[0]  && objectPath[1] ) {

      let section = model['cover']['model']['modelData'][objectPath[0]][objectPath[1]];

      // console.log(model);

      if(objectPath[2] && section[objectPath[2]]) {
        value = section[objectPath[2]];
      }
        
        //console.log(value);

    }
    
    if(value === '')
      value = 'N/A';

    return value;

  }

  toArrayObjects(obj) {

    console.log(obj)
    return Object.keys(obj);
 }

 toArray(obj) {
  return Object.keys(obj).map(key => obj[key]) 
}

 editSelectedSectionInFormModal (key) {
  
  this.submitLinkText = "Please wait...";
   
  this.createModelForm(this.config, key);
  const modalRef = this.modalService.open(DynamicFormModalComponent);

  modalRef.componentInstance.data = this.modelData['cover']['model']['modelData']['formFieldsEntities'];
  modalRef.componentInstance.form = this.modelFormTemplate;
  modalRef.componentInstance.postEndpoint = this.endpoint;
  modalRef.componentInstance.formId = this.modelQueryId;
  

}

ngOnChanges(changes: SimpleChanges) {
  // only run when property "data" changed
  if (changes['modelInitData']) {
     // alert('changed')
      this.modelData = changes['modelInitData'].currentValue;
  }

  if (changes['modelId']) {

      
   
      if(this.modelQueryId != changes['modelId'].currentValue) {
        this.modelQueryId = changes['modelId'].currentValue;

       

        if(environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFields) {

          //alert('changed')
       
          //console.log(this.formFieldObjects)
          
          this.formFieldObjects = environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFieldObjects;
    
          //lets check which form groups to add that have filled in sections
          
          this.accodionSettings= environment.dashboardConfig.modelSettings[this.modelName].accodionDisplayInfo.formFieldObjects['01'];
           
          this.selectedGroupKey[0] = this.accodionSettings.sections[0].section;
          this.formGroupsWithPopulatedSections = [];
        } 

        this.selected = this.accodionSettings.sections[0].section;

        this.sectionFiles = {};

        this.createModelForm(this.config, 0);

        this.queryModelFromApi(this.config);
      } 
      // this.modelQueryId = this.modelId;
  }
}

  getFieldValue(myObj, section ,key) {
    if(myObj.hasOwnProperty(section)) {

      let sectionObj = myObj[section];

      if(sectionObj.hasOwnProperty(key)) {
          return sectionObj[key];
      }

    }

    else  
      "";
  }

  checkToIncludeSection(section ) {

    let myObj = this.modelData['cover']['model']['modelData']['formFieldsEntities'];
    if(myObj.hasOwnProperty(section)) {
        return true;
    }

    return false;
  }

}
