import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

//view components
import { DynamicDataTableComponent } from './dynamic-data-table/dynamic-data-table.component';

@NgModule({
  declarations: [DynamicDataTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    MDBBootstrapModule.forRoot()
  ],
  exports:      [ DynamicDataTableComponent ]
})
export class DataTableModule { }
