import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from './view-components/main-layout/main-layout.component';
import { BlankLayoutComponent } from './view-components/blank-layout/blank-layout.component';

import { ModelDetailsWithHistoryComponent } from './system-pages/application-pages/model-details-with-history/model-details-with-history.component';
import { DashboardLandingPageComponent } from './system-pages/application-pages/dashboard-landing-page/dashboard-landing-page.component';
import { UserContextModelsComponent } from './system-pages/application-pages/user-context-models/user-context-models.component';
import { ModelListViewComponent } from './system-pages/application-pages/model-list-view/model-list-view.component';
import { LoginPageComponent } from './system-pages/auth-pages/login-page/login-page.component';
// import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './_services/auth.guard';
import { AddModelComponent } from './system-pages/application-pages/add-model/add-model.component';
import { SystemFileImportComponent } from './system-pages/application-pages/system-file-import/system-file-import.component';
import { CustomSummaryTableComponent } from './client/_partials/custom-summary-table/custom-summary-table.component';
import { SummaryPageComponent } from './client/pages/summary-page/summary-page.component';
import { SummaryTableEditorComponent } from './client/_partials/summary-table-editor/summary-table-editor.component';

// import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
// import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
// import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

// const adminRoutes: Routes = [
//     {
//       path: 'dashboard',
//       loadChildren: './views/dashboard/dashboard.module#DashboardModule'
//     },
//     {
//       path: 'uikits',
//       loadChildren: './views/ui-kits/ui-kits.module#UiKitsModule'
//     }
//   ];

const routes: Routes = [

  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

  //auth pages
  { path: 'sessions', component: LoginPageComponent},
  { path: 'sessions/login', component: LoginPageComponent },
  { path: 'sessions/reset-password', component: LoginPageComponent },



  //dashboard pages
  { path: 'dashboard', component: DashboardLandingPageComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/home', component: DashboardLandingPageComponent, canActivate: [AuthGuard] },

  { path: 'dashboard/model-details', component: ModelDetailsWithHistoryComponent, canActivate: [AuthGuard] }, //to be removd
  { path: 'dashboard/model-details/:modelName/:id', component: ModelDetailsWithHistoryComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' }, 
  { path: 'dashboard/model-details-view/:modelName/:id', component: ModelDetailsWithHistoryComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' }, 

  { path: 'dashboard/model-history', component: ModelDetailsWithHistoryComponent, canActivate: [AuthGuard] },

  { path: 'dashboard/context-models', component: UserContextModelsComponent, canActivate: [AuthGuard] }, //to be removd
  { path: 'dashboard/context-models/:modelName/:contextId', component: UserContextModelsComponent, canActivate: [AuthGuard] },

  { path: 'dashboard/model-list/:modelName', component: ModelListViewComponent, canActivate: [AuthGuard] }, //to be removd
  
  { path: 'dashboard/add-model/:modelName', component: AddModelComponent, canActivate: [AuthGuard] }, //to be removd

  { path: 'dashboard/add-model/:modelName/:id', component: AddModelComponent, canActivate: [AuthGuard] }, //to be removd

  { path: 'dashboard/upload-model/:modelName', component: SystemFileImportComponent, canActivate: [AuthGuard] }, //to be removd

  { path: 'dashboard/summary-list', component: CustomSummaryTableComponent, canActivate: [AuthGuard] },

  { path: 'dashboard/summary-list/:PageNumber', component: CustomSummaryTableComponent, canActivate: [AuthGuard] },

  { path: 'dashboard/clipboard/summary-stats', component: SummaryPageComponent, canActivate: [AuthGuard] },

  { path: 'dashboard/summary-editor', component: SummaryTableEditorComponent, canActivate: [AuthGuard] },

  { path: 'dashboard/summary-editor/:PageNumber', component: SummaryTableEditorComponent, canActivate: [AuthGuard] },



  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
