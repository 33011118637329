import { Component, OnInit, Output, AfterViewInit } from '@angular/core';
import { Router  , ActivatedRoute } from '@angular/router';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-model-details-with-history',
  templateUrl: './model-details-with-history.component.html',
  styleUrls: ['./model-details-with-history.component.scss']
})
export class ModelDetailsWithHistoryComponent implements OnInit, AfterViewInit{

  modelId;
  modelName;

  modelCount;
  modelQueryId;
  modelData;

  dataReady;
  pageReady = false;
  pageReload = false;
  models;
  config;

  constructor(private route: ActivatedRoute,private router: Router,private _data: CommonDataService) { }

  ngOnInit() {

    this.models = environment.dashboardConfig.modelSettings.userContextModels.models;
    

    this.route.params.subscribe((params) => {

      if(params['modelName']) {
        // this.modelName = params['modelName'];
        if(this.modelName != params['modelName']) {
          this.modelName = params['modelName'];
        }
      }

      if(params['id']) {
        

        if(this.modelId != params['id']) {
          this.modelId = params['id'];
          this.pageReload = true;
          this.pageReady = false;
          //this.router.navigate(['/dashboard/model-list/'+this.modelName, this.modelId]) .then(() => window.location.reload());
          this.queryModelFromApi();
        }
      }

      this.config = environment.dashboardConfig.modelSettings[this.modelName].partialInfoSetup;

      
      
    });

    this.queryModelFromApi();
  }

  ngAfterViewInit() {
    this.pageReady = true;
  }


  queryModelFromApi() {

    let queryData = new FormData();
    queryData.append('modelName', this.modelName);
  
    if(parseInt(this.modelId) > 0) {
      queryData.append('mid', this.modelId);
    }
  
    this._data.getModelData(queryData) 
    .pipe(first())
    .subscribe(
  
      data => {
        
        
        this.modelCount = Object.keys(data['covers']).length;
        this.modelData = data['covers'][this.modelCount - 1];

        this.dataReady = true;
  
        //console.log(this.modelData);

        
  
      },
  
      error => {
        console.log(error);
        //   iziToast.success({
        //     title: 'Error!',
        //     position: 'topRight',
        //     icon: 'icon-circle-check',
        //     message: 'Failed to retrieve profile info.'
        // });
  
      },
  
      () => {
          this.readyState();
      }
    );
  
  }

  readyState() {
    if(this.pageReload)
      this.pageReady = true;
  }

  onNotify(event, componentName) {

  }

}
