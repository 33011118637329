import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  runValidations (field: any) {
  
      var validations = [];
      let validatorsAll: any[] = []; // var to hold all validations

      if(!field.validations)
        return validatorsAll;

      validations = Object.values(field.validations); // var to collect the validation flags to search

      //custom validations
      var custom_validations = validations.filter(s => s.includes('custom-'));
      var minNumber = validations.filter(s => s.includes('minNumber-'));
      var maxNumber = validations.filter(s => s.includes('maxNumber-'));
      var minLength = validations.filter(s => s.includes('minLength-'));
      var maxLength = validations.filter(s => s.includes('maxLength-'));
      var pattern = validations.filter(s => s.includes('pattern-'));
        
      // Validators.required
      if (validations.includes('required')){
        validatorsAll.push(Validators.required) // push the validation to master var
      };

      // Validators.email
      if (validations.includes('email')){
        validatorsAll.push(Validators.email)
      };

      //run though all the min validators
      minNumber.forEach(function (value) {
        var minValue = value.split("minNumber-", 1);
        if(minValue[1]) {
          var val = parseInt(minValue[1]);
          validatorsAll.push(Validators.min(val));
        }
      }); 


      maxNumber.forEach(function (value) {
        var maxValue = value.split("maxNumber-", 1);
        if(maxValue[1]) {
          var val = parseInt(maxValue[1]);
          validatorsAll.push(Validators.max(val));
        }
      }); 


      minLength.forEach(function (value) {
        var minLengthValue = value.split("minLength-", 1);
        if(minLengthValue[1]) {
          var val = parseInt(minLengthValue[1]);
          validatorsAll.push(Validators.minLength(val));
        }
      }); 

      maxLength.forEach(function (value) {
        var maxLengthValue = value.split("maxLength-", 1);
        if(maxLengthValue[1]) {
          var val = parseInt(maxLengthValue[1]);
          validatorsAll.push(Validators.maxLength(val));
        }
      }); 


      pattern.forEach(function (value) {
        var patternValue = value.split("pattern-", 1);
        if(patternValue[1]) {
          var patternRegExString = patternValue[1];
          validatorsAll.push(Validators.pattern(patternRegExString));
        }
      }); 


      return validatorsAll;

  }


  proccessCustomValidations(custom: any) {

  }
}
